import React, { useEffect, useState } from 'react';
// import slugify from 'slugify';
import { Input, Button, Checkbox, Form, Row, Col } from 'antd';
import { validateMessages } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';

const formInit = {
  _id: null,
  clientType: [],
  clientName: '',
  address: '',
  city: '',
  phone: '',
  notes: '',
};
const clientType = [
  { label: 'kupac', value: 'kupac' },
  { label: 'prodavac', value: 'prodavac' },
  { label: 'punomoćnik', value: 'punomoćnik' },
  { label: 'suvlasnik', value: 'suvlasnik' },
  { label: 'zakupodavac', value: 'zakupodavac' },
  { label: 'zakupac', value: 'zakupac' },
];
const ClientForm = ({ data, onSubmit }) => {
  const [form] = Form.useForm();
  const [checkedList, setCheckedList] = useState();
  const [indeterminate, setIndeterminate] = useState(true);
  let initialValues = { ...formInit, ...data };

  const onFinish = async (values) => {
    if (values.email === '') {
      values.email = 'Nema podatka';
    }
    onSubmit(values);
  };
  const customValidateMessages = {
    types: {
      email: 'Molimo Vas unesite tačnu e-mail adresu!',
    },
  };
  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < clientType.length);
  };
  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          validateMessages={customValidateMessages}
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <Row type='flex' gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label='Vrsta klijenta'
                    name='clientType'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo Vas da unesete vrstu klijenta!',
                      },
                    ]}
                  >
                    <Checkbox.Group
                      // style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}
                      value={checkedList}
                      onChange={onChange}
                    >
                      {clientType.map((option) => (
                        <Checkbox key={option.value} value={option.value}>
                          {option.label}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label='Ime i prezime klijenta'
                    name='clientName'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo Vas da unesete ime i prezime klijenta!',
                      },
                    ]}
                  >
                    <Input placeholder='Unesite ime i prezime...' />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item label='JMBG' name='jmbg'>
                    <Input placeholder='Unesite jmbg...' />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item label='Broj lične karte' name='brLk'>
                    <Input placeholder='Unesite broj lične karte...' />
                  </Form.Item>
                </Col>
              </Row>
              <Row type='flex' gutter={16}>
                <Col span={8}>
                  <Form.Item label='Adresa' name='address'>
                    <Input placeholder='Unesite adresu...' />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label='Grad' name='city'>
                    <Input placeholder='Unesite grad...' />
                  </Form.Item>
                </Col>
              </Row>
              <Row type='flex' gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label='Broj telefona'
                    name='phone'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo Vas da unesete broj telefona!',
                      },
                    ]}
                  >
                    <Input placeholder='Unesite broj telefona...' />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label='E-mail adresa' name='email'>
                    <Input type='email' placeholder='Unesite email adresu...' />
                  </Form.Item>
                </Col>
              </Row>
              <Row type='flex' gutter={16}>
                <Col span={16}>
                  <Form.Item label='Beleške' name='notes'>
                    <TextArea rows={7} placeholder='Unesite beleške...' />
                  </Form.Item>
                </Col>
              </Row>

              {/* <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={8}>
                    <Form.Item label='Featured' name='featuredProduct' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                  </Col>
                </Row> */}

              {/* <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={8}>
                    <Form.Item label='Featured' name='featuredAd' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item label='Featured until' name='featuredAdUntil' getValueProps={() => {}} rules={[{ type: 'object' }]}>
                      <DatePicker style={{ width: '100%' }} size='large' rules={[{ type: 'object' }]} />
                    </Form.Item>
                  </Col>
                </Row> */}
            </Col>
          </Row>

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              Kreiraj klijenta
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ClientForm;
