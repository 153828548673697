import React, { useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification, Divider } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { EOPForm } from '../../components/forms';

const CreateEOP = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', {}, currentuser.data.token, 'get');
  const [eop, fetchEop] = useAxios('', {}, currentuser.data.token, 'get');
  const { dataId } = props.match.params;
  useEffect(() => {
    fetchData(
      `${SERVER_URL}/data?filter=` +
        encodeURIComponent(
          JSON.stringify({
            contractDate: { $ne: null },
          }),
        ),
      [],
    );
    if (dataId) fetchEop(`${SERVER_URL}/eop/${dataId}`, {});
  }, [eop, data, dataId]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/eop` : `${SERVER_URL}/eop/${dataId}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `EOP ${isNew ? 'je kreiran.' : 'je ažuriran.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin/eop');
      window.location.reload();
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;

      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const isEopFetched = !eop.isLoading && eop.data && currentuser.language;
  const isDataFetched = !data.isLoading && data.data && currentuser.language;
  return (
    <div className='edit-panel'>
      <div className='actions-block-eop'>
        <h2 className='data-title' style={{ paddingBottom: '15px', paddingLeft: '15px', textAlign: 'left' }}>
          EOP forma
        </h2>
        <Link to='/admin/eop'>
          <Button type='primary'>Nazad na listu EOP-a</Button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}

        {!dataId && isDataFetched && data.data && (
          <EOPForm
            isNew={true}
            realEstates={data.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}

        {dataId && !data.isError && data.data && eop.data && isDataFetched && isEopFetched && (
          <EOPForm
            isNew={false}
            data={eop.data}
            realEstates={data.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default CreateEOP;
