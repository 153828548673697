import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/DemandTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'notCurrentDate',
  'user',
  'createdAt',
  'updatedAt',
  'client',
  'location',
  'note',
  'structure',
  'structureName',
  'createdByUser',
];

const Demand = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL}/demand`, []);
  }, [fetchData]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/demand/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Potražnja je obrisana.',
        placement: 'bottomRight',
      });
      window.location.reload();
    } catch (err) {
      notification.error({
        message: 'Problem pri brisanju. Molimo pokušajte ponovo.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let updatedColumnKeys;
  const desiredKeysClient = ['clientName'];
  const desiredKeysLocation = ['locationName'];
  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    if (data.data.items[0].client !== undefined) {
      const clientKeys = Object.keys(data.data.items[0].client).filter((key) => desiredKeysClient.includes(key));
      updatedColumnKeys = columnKeys.concat(clientKeys.filter((key) => !columnKeys.includes(key)));
    }
    if (data.data.items[0].location !== undefined) {
      const locationKeys = Object.keys(data.data.items[0].location).filter((key) => desiredKeysLocation.includes(key));
      updatedColumnKeys = updatedColumnKeys.concat(locationKeys.filter((key) => !updatedColumnKeys.includes(key)));
    }
  }

  let tableData = [];

  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();

      item.locationName =
        item.location !== undefined
          ? item.location !== null
            ? item.location.locationName
            : 'Nema podatka'
          : 'Nema podatka';
      item.clientName =
        item.client !== undefined ? (item.client !== null ? item.client.clientName : 'Nema podatka') : 'Nema podatka';

      return item;
    });
  }

  return (
    <div className='table data-table'>
      <div className='actions-block'>
        <Link to='/admin/new-demand'>
          <Button type='primary'>Kreiranje Potražnje</Button>
        </Link>
      </div>

      <div style={{ textAlign: 'center' }}>
        {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteDataHandler}
            columnKeys={updatedColumnKeys}
            title='Data'
            editPath='/admin/edit-demand/'
            viewPath='/admin/demand-view/'
          />
        )}
        {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && (
          <div className='no-data-box'>
            <h2>Nema podataka</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Demand;
