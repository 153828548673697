import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
// import { useHistory } from 'react-router-dom';
import { notification, Drawer /* , Button */ } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { ClientModalForm } from '../../components/forms';

const EditClientModal = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  // const history = useHistory();
  const userId = props.userId;
  const { showModal, setShowModal } = props;

  useEffect(() => {
    if (userId) {
      fetchUser(`${SERVER_URL}/clients/${userId}`, []);
    }
  }, [userId, fetchUser]);

  const updateClient = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/clients/${userId}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: 'Klijent je ažuriran.',
        placement: 'bottomRight',
      });
      window.location.reload();
    } catch (err) {
      notification.error({
        message: 'Problem pri ažuriranju. Molimo pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const onClose = () => {
    props.setShowModal(false);
  };

  return (
    <>
      <div className={showModal ? 'overlay show' : 'overlay'} onClick={() => setShowModal(!showModal)} />
      <div className={showModal ? 'modal show' : 'modal'}>
        {/* <div className='actions-block'>
        <Link to='/admin/users'>
          <Button type='primary'>All users</Button>
        </Link>
      </div> */}

        <div style={{ textAlign: 'center', height: '100%' }}>
          {userId && user.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
          {userId && !user.isLoading && !user.isError && user.data && user.data.email && (
            <Drawer title={'Ispravi klijenta'} placement='right' onClose={onClose} open={showModal} width={420}>
              <ClientModalForm
                data={user.data}
                updateHandler={updateClient}
                SERVER_URL={SERVER_URL}
                token={currentuser.data.token}
                showModal={showModal}
                setShowModal={setShowModal}
                profile={props.profile}
              />
            </Drawer>
          )}
        </div>
      </div>
    </>
  );
};

export default EditClientModal;
