import React, { useEffect, useState } from 'react';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd';
import UploadBox from './UploadBox';
import { Input, Form } from 'antd';
import { useDrag, useDrop } from 'react-dnd';
const DNDPrivGallery = ({
  form,
  setPrivGallery,
  currentGallery,
  editPrivGallerySingleImageTrigger,
  deletePrivGalleryImageHandler,
}) => {
  const [width, setWidth] = useState();
  const list = currentGallery ? currentGallery : [];
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    const dndDropzone = document.getElementById('dnd-dropzone');
    setWidth(dndDropzone.offsetWidth);
    window.addEventListener('resize', () => setWidth(dndDropzone.offsetWidth));
    return () => window.removeEventListener('resize', () => setWidth(dndDropzone.offsetWidth));
  }, []);

  const handleImageDesc = (value, imageId) => {
    list.forEach((image) => {
      if (imageId === image._id) {
        image.pictureDesc = value;
      }
    });
    setPrivGallery([...list]);
  };

  const DraggableUploadBox = ({ index, image }) => {
    const [{ isDragging }, drag] = useDrag({
      type: 'IMAGE',
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: 'IMAGE',
      hover: () => {
        setHoveredIndex(index); // Set the hovered index when the box is being hovered
      },
    });

    const handleDragOver = (e) => {
      e.preventDefault(); // Prevent default behavior to enable drop
      setHoveredIndex(index); // Set the hovered index when dragging over
    };

    return (
      <div
        ref={(node) => drag(drop(node))}
        onDragOver={handleDragOver} // Add the dragOver event handler
        onDragLeave={() => setHoveredIndex(null)} // Reset hovered index when leaving
        onDrop={() => setHoveredIndex(null)} // Reset hovered index when dropping
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: 'move',
          border: hoveredIndex === index ? '2px solid red' : '2px solid transparent', // Example styling for the hovered box
          visibility: isDragging ? 'hidden' : 'visible',
        }}
      >
        <UploadBox
          editHandler={() => editPrivGallerySingleImageTrigger(index, image?._id)}
          deleteHandler={() => deletePrivGalleryImageHandler(image?._id)}
          image={image?.url}
          index={index}
          name='privGallery'
        />
      </div>
    );
  };

  const handleDrop = (dragIndex, hoverIndex) => {
    console.log('drag', dragIndex, 'hover', hoverIndex);
    const draggedImage = list[dragIndex];
    const updatedList = [...list];
    updatedList.splice(dragIndex, 1);
    updatedList.splice(hoverIndex, 0, draggedImage);
    setPrivGallery(updatedList);
    setHoveredIndex(null); // Reset the hovered index after the drop
  };

  const [{ isOver }, drop] = useDrop({
    accept: 'IMAGE',
    drop: (item) => handleDrop(item.index, hoveredIndex),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      id='dnd-dropzone'
      ref={drop}
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        margin: '8px 0',
        border: isOver ? '2px dashed #1890ff' : '2px dashed #d9d9d9',
      }}
    >
      {list.map((item, index) => (
        <div key={index} style={{ display: 'flex', gap: '30px', alignItems: 'center', margin: '20px 0' }}>
          <DraggableUploadBox key={index} index={index} image={item} />
          <label htmlFor=''>Opis slike:</label>
          <Input type='text' value={item?.pictureDesc} onChange={(e) => handleImageDesc(e.target.value, item?._id)} />
        </div>
      ))}
    </div>
  );
};

export default DNDPrivGallery;
