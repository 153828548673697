import React from 'react';

const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
  const handleClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      handlePageChange(pageNumber);
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];

    for (let i = 1; i <= 3 && i <= totalPages; i++) {
      buttons.push(
        <button key={i} onClick={() => handleClick(i)} className={currentPage === i ? 'active' : ''}>
          {i}
        </button>,
      );
    }

    if (currentPage > 5) {
      buttons.push(<span key='startEllipsis'>...</span>);
    }

    for (let i = currentPage - 1; i <= currentPage + 1 && i <= totalPages; i++) {
      if (i > 3 && i < totalPages - 2) {
        buttons.push(
          <button key={i} onClick={() => handleClick(i)} className={currentPage === i ? 'active' : ''}>
            {i}
          </button>,
        );
      }
    }

    if (currentPage < totalPages - 3) {
      buttons.push(<span key='endEllipsis'>...</span>);
    }

    for (let i = totalPages - 2; i <= totalPages; i++) {
      if (i > 3) {
        buttons.push(
          <button key={i} onClick={() => handleClick(i)} className={currentPage === i ? 'active' : ''}>
            {i}
          </button>,
        );
      }
    }

    return buttons;
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <div className='pagination'>
        <button disabled={currentPage === 1} onClick={() => handleClick(currentPage - 1)}>
          <img src='/pagination-arrow-left.svg' alt='share' />
        </button>
        {renderPaginationButtons()}
        <button disabled={currentPage === totalPages} onClick={() => handleClick(currentPage + 1)}>
          <img src='/pagination-arrow-right.svg' alt='share' />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
