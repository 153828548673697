import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button, Form, DatePicker, Row, Select } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/DataTable';
import { SERVER_URL } from '../../config';
import FormItem from 'antd/es/form/FormItem';
const { RangePicker } = DatePicker;
const { Option } = Select;
const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'heating',
  'webDesc',
  'privDesc',
  'featureImage',
  'flag',
  'views',
  'currentDate',
  'notCurrentDate',
  'gallery',
  'privGallery',
  'comments',
  'user',
  'createdAt',
  'updatedAt',
  'sold',
  'structure',
  'isExpiredContract',
  'isMailSent',
  'client',
  'location',
  'createdByUser',
  'soldByUser',
  'rented',
  'postedCetiriZida',
  'clientGaveUp',
  'googleDescription',
  'createdByUserDate',
  'soldByUserDate',
  'postedAt',
  'coOwners',
  'ownerSold',
  'url',
  'cadastralTownship',
  'commissioner',
  'invoicedAmount',
];

const Statistic = () => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [users, fetchUsers] = useAxios('', {}, currentuser.data.token, 'get');
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [filter, setFilter] = useState({});
  const history = useHistory();

  const onFinish = (values) => {
    let fetchFilter;
    if (values.option === 'kreirano') {
      fetchFilter = {
        createdByUser: values.user,
        createdByUserDate: {
          $gte: values.dateRange[0],
          $lte: values.dateRange[1],
        },
      };
    }
    if (values.option === 'prodato') {
      fetchFilter = {
        soldByUser: values.user,
        soldByUserDate: {
          $gte: values.dateRange[0],
          $lte: values.dateRange[1],
        },
      };
    }
    setFilter(fetchFilter);
  };

  useEffect(() => {
    fetchData(`${SERVER_URL}/data?filter=` + encodeURIComponent(JSON.stringify(filter)), []);
    fetchUsers(
      `${SERVER_URL}/users?filter=` + encodeURIComponent(JSON.stringify({ role: { $nin: ['superAdmin'] } })),
      [],
    );
  }, [fetchData, fetchUsers, filter]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/data/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Nekretnina je obrisana.',
        placement: 'bottomRight',
      });
      window.location.reload();
    } catch (err) {
      if (err.response && err.response.status === 400) {
        notification.error({
          message: err.response.data.message,
          placement: 'bottomRight',
        });
      } else {
        notification.error({
          message: 'Problem pri brisanju. Molimo pokušajte ponovo.',
          placement: 'bottomRight',
        });
      }
    }
  };
  let columnKeys;
  let updatedColumnKeys;
  const desiredKeysClient = ['clientName'];
  const desiredKeysLocation = ['locationName'];
  if (data.data && data.data.items && data.data.items.length > 0) {
    let firstItem = data.data.items[0];
    for (const item of data.data.items) {
      console.log(item);
      if (item.client !== undefined && item.client !== null && item.location !== undefined && item.location !== null) {
        // Found the desired item, create an object with all properties
        firstItem = { ...item };
        break; // Exit the loop after finding the first matching item
      }
    }

    const keys = Object.keys(firstItem);

    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    updatedColumnKeys = columnKeys;

    if (firstItem.client !== undefined && firstItem.client !== null) {
      const clientKeys = Object.keys(firstItem.client).filter((key) => desiredKeysClient.includes(key));

      updatedColumnKeys = [...new Set(updatedColumnKeys.concat(clientKeys))];
    }

    if (firstItem.location !== undefined && firstItem.location !== null) {
      const locationKeys = Object.keys(firstItem.location).filter((key) => desiredKeysLocation.includes(key));

      updatedColumnKeys = [...new Set(updatedColumnKeys.concat(locationKeys))];
    }
  }

  let tableData = [];

  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();

      const regex = /^(\d{4})-(\d{2})-(\d{2}).*/;
      const match = item.contractDate !== null && item.contractDate.match(regex);

      if (match) {
        const [, year, month, day] = match;
        const date = `${day}.${month}.${year}.`;
        item.contractDate = date;
      }

      item.contractDate = item.contractDate || 'Nema podatka';

      item.locationName = item.location?.locationName || 'Nema podatka';
      item.clientName = item.client?.clientName || 'Nema podatka';

      item.realEstateId = item.realEstateId;
      if (item.published === true) item.published = 'Da';
      if (item.published === false) item.published = 'Ne';
      return item;
    });
  }

  return (
    <div className='table'>
      <div className='statistic-block'>
        <h3>Filter:</h3>
        {!users.isLoading && users.data && users.data.items && users.data.items.length > 0 && (
          <Form className='form-horizontal' onFinish={(values) => onFinish(values)} layout='horizontal' form={form}>
            <Row type='flex' gutter={0}>
              <FormItem
                label='Period'
                name='dateRange'
                rules={[
                  {
                    required: true,
                    message: 'Molimo Vas da izaberete period.',
                  },
                ]}
              >
                <RangePicker format='DD.MM.YYYY.' placeholder={['Datum od', 'Datum do']} />
              </FormItem>
              <Form.Item
                label='Zaposleni'
                name='user'
                rules={[
                  {
                    required: true,
                    message: 'Molimo Vas da izaberete zaposlenog.',
                  },
                ]}
              >
                <Select virtual={false} placeholder='Izaberite zaposlenog'>
                  {users.data.items.map((user, key) => (
                    <Option key={key} value={user._id}>{`${user.firstName} ${user.lastName}`}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label='Kreirane/prodate nekretnine'
                name='option'
                rules={[
                  {
                    required: true,
                    message: 'Molimo Vas da izaberete jednu od ponudjenih opcija.',
                  },
                ]}
              >
                <Select virtual={false} placeholder='Izaberite opciju'>
                  {['prodato', 'kreirano'].map((option, key) => (
                    <Option key={key} value={option}>{`${option}`}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Button type='primary' htmlType='submit'>
                Pretraži
              </Button>
            </Row>
          </Form>
        )}
        {filter.createdByUser ? (
          <>
            <hr />
            <div className='statistic-block-total'>
              <h3>Ukupno kreiranih nekretnina:</h3>
              <h3>{data.data.items.length}</h3>
            </div>
          </>
        ) : filter.soldByUser ? (
          <>
            <hr />
            <div className='statistic-block-total'>
              <h3>Ukupno prodatih nekretnina:</h3>
              <h3>{data.data.items.length}</h3>
            </div>
          </>
        ) : (
          ''
        )}
      </div>

      <div style={{ textAlign: 'center' }}>
        {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteDataHandler}
            columnKeys={updatedColumnKeys}
            title='Data'
            editPath='/admin/edit-data/'
            viewPath='/admin/view-data/'
            showButton={false}
          />
        )}
        {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && (
          <div className='no-data-box'>
            <h2>Nema podataka</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Statistic;
