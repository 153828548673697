import Users from '../pages/users/Users';
import Clients from '../pages/clients/Clients';
import ClientsBuyers from '../pages/clients/ClientsBuyers';
import CreateEOP from '../pages/eop/CreateEOP';
import EOP from '../pages/eop/Eop';
import ClientsSellers from '../pages/clients/ClientsSellers';
import ViewClient from '../pages/clients/ViewClient';
import NewBuyer from '../pages/clients/NewBuyer';
import NewSeller from '../pages/clients/NewSeller';
import NewClient from '../pages/clients/NewClient';
import NewCoOwner from '../pages/clients/NewCoOwner';
import NewCommissioner from '../pages/clients/NewCommissioner';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import Data from '../pages/data/Data';
// import DataDraft from '../pages/data/DataDraft';
import Statistic from '../pages/statistic/Statistic';
import DataNotActive from '../pages/data/DataNotActive';
import DataContract from '../pages/data/DataContract';
import ViewData from '../pages/data/ViewData';
import ViewDataContract from '../pages/data/ViewDataContract';
import ViewDataNotActive from '../pages/data/ViewDataNotActive';
import EditData from '../pages/data/EditData';
import Demand from '../pages/demand/Demand';
import EditDemand from '../pages/demand/EditDemand';
import ViewDemand from '../pages/demand/ViewDemand';
import Gallery from '../pages/gallery/Gallery';
import GalleryNewContent from '../pages/gallery/GalleryNewContent';
import ClientsCoOwners from '../pages/clients/ClientsCoOwners';
import ClientsCommissioners from '../pages/clients/ClientsCommissioners';
import ClientsTenants from '../pages/clients/ClientsTenants';
import ClientsLessors from '../pages/clients/ClientsLessors';
import EditDataContract from '../pages/data/EditDataContract';
import EditDataNotActive from '../pages/data/EditDataNotActive';
import ViewBuyer from '../pages/clients/ViewBuyer';
import ViewSeller from '../pages/clients/ViewSeller';
import ViewCoOwner from '../pages/clients/ViewCoOwner';
import ViewCommissioner from '../pages/clients/ViewCommissioner';
import EopStatistic from '../pages/eop/EopStatistic';

const user = JSON.parse(sessionStorage.getItem('user'));
const role = user ? user.role : ['admin'];

const routes = [
  {
    label: 'Klijenti',
    icon: `${process.env.REACT_APP_PROD_DASHBOARD_URL}users.svg`,
    showInMenu: true,
    children: [
      {
        label: 'Svi Klijenti',
        path: '/admin/clients',
        component: Clients,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
      {
        label: 'Kupci',
        path: '/admin/clients-buyers',
        component: ClientsBuyers,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
      {
        label: 'Prodavci',
        path: '/admin/clients-sellers',
        component: ClientsSellers,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
      {
        label: 'Suvlasnici',
        path: '/admin/clients-coowners',
        component: ClientsCoOwners,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
      {
        label: 'Punomoćnici',
        path: '/admin/clients-commissioners',
        component: ClientsCommissioners,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
      {
        label: 'Zakupodavci',
        path: '/admin/clients-lessors',
        component: ClientsLessors,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
      {
        label: 'Zakupci',
        path: '/admin/clients-tenants',
        component: ClientsTenants,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
      {
        label: 'Dodaj Novog Klijenta',
        path: '/admin/new-client',
        component: NewClient,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
      {
        label: 'Dodaj Novog Kupca',
        path: '/admin/new-buyer',
        component: NewBuyer,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
      {
        label: 'Dodaj Novog Prodavca',
        path: '/admin/new-seller',
        component: NewSeller,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
      {
        label: 'Dodaj Novog Suvlasnika',
        path: '/admin/new-coowner',
        component: NewCoOwner,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
      {
        label: 'Dodaj Novog Punomoćnika',
        path: '/admin/new-commissioner',
        component: NewCommissioner,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
      // {
      //   label: 'Edit User',
      //   path: '/admin/edit-user/:userId',
      //   component: EditUser,
      //   allowed: ['admin'],
      //   showInMenu: false,
      // },
      {
        label: 'View',
        path: '/admin/view-client/:id',
        component: ViewClient,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
      {
        label: 'View',
        path: '/admin/view-buyer/:id',
        component: ViewBuyer,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
      {
        label: 'View',
        path: '/admin/view-seller/:id',
        component: ViewSeller,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
      {
        label: 'View',
        path: '/admin/view-coowner/:id',
        component: ViewCoOwner,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
      {
        label: 'View',
        path: '/admin/view-commissioner/:id',
        component: ViewCommissioner,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
    ],
  },

  {
    label: 'Galerija',
    allowed: ['admin', 'agent'],
    showInMenu: true,
    icon: `${process.env.REACT_APP_PROD_DASHBOARD_URL}gallery.svg`,
    children: [
      {
        label: 'Sve slike',
        path: '/admin/gallery',
        component: Gallery,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
      {
        label: 'Dodavanje novih slika',
        path: '/admin/gallery/new-content',
        component: GalleryNewContent,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Nekretnine',
    icon: `${process.env.REACT_APP_PROD_DASHBOARD_URL}nekretnine-icon.svg`,
    showInMenu: true,
    children: [
      {
        label: 'Aktivne Nekretnine',
        path: '/admin/data',
        component: Data,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
      // {
      //   label: 'Nekretnine draft',
      //   path: '/admin/data-draft',
      //   component: DataDraft,
      //   allowed: ['admin', 'agent'],
      //   showInMenu: true,
      // },
      {
        label: 'Kreiranje Nekretnina',
        path: '/admin/new-data',
        component: EditData,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
      {
        label: 'Pregled Nekretnine',
        path: '/admin/view-data/:id',
        component: ViewData,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
      {
        label: 'Izmena Nekretnine',
        path: '/admin/edit-data/:dataId',
        component: EditData,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Nekretnine Ugovor',
    icon: `${process.env.REACT_APP_PROD_DASHBOARD_URL}nekretnine-ugovor-icon.svg`,
    showInMenu: true,
    children: [
      {
        label: 'Svi Ugovori',
        path: '/admin/data-contract',
        component: DataContract,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
      {
        label: 'Pregled nekretnine ugovor',
        path: '/admin/view-data-contract/:id',
        component: ViewDataContract,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
      {
        label: 'Izmena Ugovora Nekretnine',
        path: '/admin/edit-data-contract/:dataId',
        component: EditDataContract,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Potražnja',
    icon: `${process.env.REACT_APP_PROD_DASHBOARD_URL}potraznja-icon.svg`,
    showInMenu: true,
    children: [
      {
        label: 'Sve Potražnje',
        path: '/admin/demand',
        component: Demand,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
      {
        label: 'Kreiranje Potražnje',
        path: '/admin/new-demand',
        component: EditDemand,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
      {
        label: 'Pregled Potražnje',
        path: '/admin/demand-view/:id',
        component: ViewDemand,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
      {
        label: 'Izmena Potražnje',
        path: '/admin/edit-demand/:id',
        component: EditDemand,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Nekretnine neaktivne',
    icon: `${process.env.REACT_APP_PROD_DASHBOARD_URL}neaktivne-nekretnine.svg`,
    showInMenu: true,
    children: [
      {
        label: 'Sve Neaktivne Nekretnine',
        path: '/admin/data-not-active',
        component: DataNotActive,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
      {
        label: 'Pregled Neaktivne Nekretnine',
        path: '/admin/view-data-not-active/:id',
        component: ViewDataNotActive,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
      {
        label: 'Izmena Neaktivne Nekretnine',
        path: '/admin/edit-data-not-active/:dataId',
        component: EditDataNotActive,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'EOP',
    icon: `${process.env.REACT_APP_PROD_DASHBOARD_URL}nekretnine-ugovor-icon.svg`,
    showInMenu: true,
    children: [
      {
        label: 'EOP Lista',
        path: '/admin/eop',
        component: EOP,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
      {
        label: 'Kreiraj EOP',
        path: '/admin/create-eop',
        component: CreateEOP,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
      {
        label: 'Statistika nekretnina',
        path: '/admin/eop-statistic',
        component: EopStatistic,
        allowed: ['admin', 'agent'],
        showInMenu: true,
      },
      {
        label: 'Izmena EOP-a',
        path: '/admin/edit-eop/:dataId',
        component: CreateEOP,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Statistika',
    icon: `${process.env.REACT_APP_PROD_DASHBOARD_URL}statistika-icon.svg`,
    showInMenu: role.includes('agent') ? false : role.includes('admin') ? true : false,
    children: [
      {
        label: 'Statistika zaposlenih',
        path: '/admin/statistic',
        component: Statistic,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Korisnički nalozi',
    icon: `${process.env.REACT_APP_PROD_DASHBOARD_URL}users.svg`,
    showInMenu: role.includes('agent') ? false : role.includes('admin') ? true : false,
    children: [
      {
        label: 'Svi Korisnici',
        path: '/admin/users',
        component: Users,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New User',
        path: '/admin/new-user',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit User',
        path: '/admin/edit-user/:userId',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'View',
        path: '/admin/view-user/:id',
        component: ViewUser,
        allowed: ['admin', 'agent'],
        showInMenu: false,
      },
    ],
  },
  //View user stoji ovde samo kao ruta koja omogucava da se klikom na ikonicu profil redirectuje na profil
  //Ne dirati
];

export default routes;
