import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import { useSelector, shallowEqual } from 'react-redux';

const ProtectedRoute = ({ component: Component, allowed, user, ...rest }) => {
  const userRoles = user && user.role.map((role) => allowed.includes(role));

  return (
    <Route
      {...rest}
      render={(props) =>
        user && userRoles.some((isTrue) => isTrue === true) ? ( // if signed in and user's role is allowed
          <Component {...props} /> // render component
        ) : (
          <Redirect to='/login' /> // else redirect
        )
      }
    />
  );
};

export default ProtectedRoute;
