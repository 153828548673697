import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Checkbox, Select, Result, Radio, notification, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { GalleryModal } from './';
import { DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';

const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 },
};
const clientType = [
  { label: 'kupac', value: 'kupac' },
  { label: 'prodavac', value: 'prodavac' },
  { label: 'punomoćnik', value: 'punomoćnik' },
  { label: 'suvlasnik', value: 'suvlasnik' },
  { label: 'zakupodavac', value: 'zakupodavac' },
  { label: 'zakupac', value: 'zakupac' },
];
const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const initialValues = {
  clientType: [],
  city: '',
  mobPhone: '',
  phone: '',
  name: '',
  address: '',
  email: '',
};

const ClientModalForm = ({ data, updateHandler, result, setResult, SERVER_URL, token, showModal, setShowModal }) => {
  const [form] = Form.useForm();
  const [checkedList, setCheckedList] = useState();
  const [indeterminate, setIndeterminate] = useState(true);
  const currentuser = useContext(UserContext);

  let client = data ? { ...data } : initialValues;

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < clientType.length);
  };

  const onFinish = (values) => {
    updateHandler(values);

    setShowModal(!showModal);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  // const onClickHandler = () => {
  //   setShowModal(!showModal);
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 2000);
  // };    dodati onClickHandler dole (izbrisan na Add user button-u)

  return (
    <>
      <div className='dashboard'>
        <div className='panel panel-body'>
          {/* eslint-disable-next-line no-useless-concat */}
          {/* <Card title={`${isNew ? 'New' : 'Edit'} user`} bordered={false}> */}
          {/* <CloseOutlined className='x-btn' onClick={() => setShowModal(false)} /> */}

          {!result && (
            <Form
              {...layout}
              name='basic'
              initialValues={client}
              onFinish={(values) => onFinish(values)}
              onFinishFailed={onFinishFailed}
              layout='horizontal'
              form={form}
            >
              <Form.Item
                label='Vrsta klijenta'
                name='clientType'
                rules={[
                  {
                    required: true,
                    message: 'Molimo Vas da unesete vrstu klijenta!',
                  },
                ]}
              >
                <Checkbox.Group
                  // style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}
                  value={checkedList}
                  onChange={onChange}
                >
                  {clientType.map((option) => (
                    <Checkbox key={option.value} value={option.value}>
                      {option.label}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>

              <Form.Item
                label='Ime'
                name='clientName'
                rules={[
                  {
                    required: true,
                    message: 'Molimo Vas da unesete ime i prezime!',
                  },
                ]}
              >
                <Input placeholder='Unesite ime i prezime...' />
              </Form.Item>

              <Form.Item label='JMBG' name='jmbg'>
                <Input placeholder='Unesite jmbg...' />
              </Form.Item>

              <Form.Item label='Broj LK' name='brLk'>
                <Input placeholder='Unesite broj lične karte...' />
              </Form.Item>

              <Form.Item label='Adresa' name='address'>
                <Input placeholder='Unesite adresu...' />
              </Form.Item>

              <Form.Item label='Grad' name='city'>
                <Input placeholder='Unesite grad...' />
              </Form.Item>

              <Form.Item label='Telefon' name='phone'>
                <Input placeholder='Unesite broj telefona...' />
              </Form.Item>

              <Form.Item label='Email' name='email'>
                <Input type='email' placeholder='Unesite email...' />
              </Form.Item>

              <Form.Item label='Beleška' name='notes'>
                <TextArea style={{ height: '300px' }} row={7} placeholder='Unesite belešku...' />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <div>
                  <Button type='primary' htmlType='submit' style={{ float: 'right', marginLeft: '15px' }}>
                    Sačuvaj
                  </Button>
                  <Button type='secondary' style={{ float: 'right' }} onClick={() => setShowModal(!showModal)}>
                    Odustani
                  </Button>
                </div>
              </Form.Item>
            </Form>
          )}

          {/* </Card> */}
        </div>
      </div>
    </>
  );
};

export default ClientModalForm;
