import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Avatar, Button, Layout, Tabs, Typography, Card, Collapse, notification } from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import TableData from '../../components/tables/DataTableClient';
import TableDemand from '../../components/tables/DemandTable';
import { UserLog } from '../../components/forms';
import dayjs from 'dayjs';
import EditUserModal from '../../components/modals/EditModal';

const TABLE_COLUMN_KEYS_DATA = [
  '_id',
  '__v',
  'heating',
  'webDesc',
  'privDesc',
  'featureImage',
  'flag',
  'views',
  'currentDate',
  'notCurrentDate',
  'gallery',
  'privGallery',
  'comments',
  'user',
  'createdAt',
  'updatedAt',
  'sold',
  'structure',
  'isExpiredContract',
  'isMailSent',
  'client',
  'location',
  'eb',
  'clientName',
  'createdByUser',
  'soldByUser',
  'rented',
  'postedCetiriZida',
  'clientGaveUp',
  'googleDescription',
  'createdByUserDate',
  'soldByUserDate',
  'postedAt',
  'coOwners',
  'ownerSold',
  'url',
  'cadastralTownship',
  'commissioner',
  'invoicedAmount',
];
const TABLE_COLUMN_KEYS_DEMAND = [
  '_id',
  '__v',
  'notCurrentDate',
  'user',
  'createdAt',
  'updatedAt',
  'client',
  'location',
  'note',
  'structure',
  'structureName',
  'clientName',
  'createdByUser',
];
const { Content, Sider } = Layout;
const { Text } = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const ViewCommissioner = (props, editPath) => {
  const { id } = props.match.params;
  const [showModal, setShowModal] = useState(false);
  const currentuser = useContext(UserContext);
  const [client, fetchClient] = useAxios('', null, currentuser.data.token, 'get');
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [demand, fetchDemand] = useAxios('', [], currentuser.data.token, 'get');
  const clientId = client?.data?._id;
  useEffect(() => {
    if (id) {
      fetchClient(`${SERVER_URL}/clients/${id}`, []);
    }
    if (clientId) {
      fetchData(
        `${SERVER_URL}/data?filter=` +
          encodeURIComponent(
            JSON.stringify({
              client: clientId,
              // $and: [
              //   { sold: { $ne: true } },
              //   { rented: { $ne: true } },
              //   { clientGaveUp: { $ne: true } },
              //   { ownerSold: { $ne: true } },
              // ],
            }),
          ),
        [],
      );
      fetchDemand(
        `${SERVER_URL}/demand?filter=` +
          encodeURIComponent(
            JSON.stringify({
              client: clientId,
            }),
          ),
        [],
      );
    }
  }, [id, data, demand, client]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/data/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Nekretnina je obrisana.',
        placement: 'bottomRight',
      });
      window.location.reload();
    } catch (err) {
      if (err.response && err.response.status === 400) {
        notification.error({
          message: err.response.data.message,
          placement: 'bottomRight',
        });
      } else {
        notification.error({
          message: 'Problem pri brisanju. Molimo pokušajte ponovo.',
          placement: 'bottomRight',
        });
      }
    }
  };

  const deleteDemandHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/demand/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Stavka je obrisana.',
        placement: 'bottomRight',
      });
      window.location.reload();
    } catch (err) {
      notification.error({
        message: 'Problem pri brisanju. Pokušajte ponovo.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let updatedColumnKeys;

  const desiredKeysLocation = ['locationName'];
  if (data.data && data.data.items && data.data.items.length > 0) {
    let firstItem = data.data.items[0];
    for (const item of data.data.items) {
      console.log(item);
      if (item.client !== undefined && item.client !== null && item.location !== undefined && item.location !== null) {
        // Found the desired item, create an object with all properties
        firstItem = { ...item };
        break; // Exit the loop after finding the first matching item
      }
    }

    const keys = Object.keys(firstItem);

    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS_DATA.includes(k));
    updatedColumnKeys = columnKeys;

    if (firstItem.location !== undefined && firstItem.location !== null) {
      const locationKeys = Object.keys(firstItem.location).filter((key) => desiredKeysLocation.includes(key));

      updatedColumnKeys = [...new Set(updatedColumnKeys.concat(locationKeys))];
    }
  }
  let tableData = [];

  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();

      const regex = /^(\d{4})-(\d{2})-(\d{2}).*/;
      const match = item.contractDate !== null && item.contractDate.match(regex);

      if (match) {
        const [, year, month, day] = match;
        const date = `${day}.${month}.${year}.`;
        item.contractDate = date;
      }

      item.contractDate = item.contractDate || 'Nema podatka';

      item.locationName = item.location?.locationName || 'Nema podatka';

      item.realEstateId = item.realEstateId;
      if (item.published === true) item.published = 'Da';
      if (item.published === false) item.published = 'Ne';
      return item;
    });
  }

  let columnKeysDemand;
  let updatedColumnKeysDemand;

  const desiredKeysLocationDemand = ['locationName'];

  if (demand.data && demand.data.items && demand.data.items.length > 0) {
    const keysDemand = Object.keys(demand.data.items[0]);
    columnKeysDemand = keysDemand.filter((k) => !TABLE_COLUMN_KEYS_DEMAND.includes(k));

    if (demand.data.items[0].location !== null) {
      const locationKeysDemand = Object.keys(demand.data.items[0].location).filter((key) =>
        desiredKeysLocationDemand.includes(key),
      );
      updatedColumnKeysDemand = columnKeysDemand.concat(
        locationKeysDemand.filter((key) => !columnKeysDemand.includes(key)),
      );
    }
  }

  let tableDataDemand = [];

  if (demand.data && demand.data.items && demand.data.items.length > 0) {
    tableDataDemand = demand.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      item.locationName =
        item.location !== undefined
          ? item.location !== null
            ? item.location.locationName
            : 'Nema podatka'
          : 'Nema podatka';

      return item;
    });
  }

  const getUrl = (itemUrl) => {
    let url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;

    return url;
  };

  return (
    <div className='edit-panel'>
      {client.data && (
        <div className='actions-block'>
          <Link to='/admin/clients-commissioners'>
            <Button type='primary'>Nazad na punomoćnike</Button>
          </Link>
        </div>
      )}

      <div className='content content-full-height'>
        {id && client.isLoading && <LoadingOutlined className='loader' spin />}
        {id && !client.isLoading && client.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {id && client && !client.isLoading && !client.isError && (
          <Layout className='site-layout-background'>
            <Content style={{ padding: '0 15px', minHeight: 280, background: '#fff' }}>
              <Tabs defaultActiveKey='1' /* onChange={callback} */>
                <TabPane tab='PREGLED' key='1'>
                  <div className='card-wrapper'>
                    <Card title='Informacije o klijentu' bordered={false}>
                      <p>
                        <Text strong>Ime i prezime: </Text>
                        {client.data ? client.data.clientName : <Text strong>Nema podatka</Text>}
                      </p>
                      <p>
                        <Text strong>Adresa: </Text>
                        {client.data ? client.data.address : <Text strong>Nema podatka</Text>}
                      </p>
                      <p>
                        <Text strong>Email: </Text>
                        {client.data ? client.data.email : <Text strong>Nema podatka</Text>}
                      </p>
                      <p style={{ textTransform: 'capitalize' }}>
                        <Text strong>Grad: </Text>
                        {client.data ? client.data.city : <Text strong>Nema podatka</Text>}
                      </p>
                      <p>
                        <Text strong>Telefon: </Text>
                        {client.data ? client.data.phone : <Text strong>Nema podatka</Text>}
                      </p>
                      <Text strong>Beleške: </Text>
                      <Collapse style={{ maxWidth: '500px', marginBottom: '20px' }}>
                        <Panel header='Prikaži beleške' key='1'>
                          {client.data ? <p>{client.data.notes}</p> : <Text strong>Nema podatka</Text>}
                        </Panel>
                      </Collapse>
                    </Card>
                  </div>
                </TabPane>
              </Tabs>
              <Tabs defaultActiveKey='1' /* onChange={callback} */>
                <TabPane tab='NEKRETNINE' key='1'>
                  <div className='table'>
                    <div style={{ textAlign: 'center' }}>
                      {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
                      {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
                        <TableData
                          data={tableData}
                          deleteHandler={deleteDataHandler}
                          columnKeys={updatedColumnKeys}
                          title='Data'
                          editPath='/admin/edit-data/'
                          viewPath='/admin/view-data/'
                        />
                      )}
                      {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && (
                        <div className='no-data-box'>
                          <h2>Nema podataka</h2>
                        </div>
                      )}
                    </div>
                  </div>
                </TabPane>
                <TabPane tab='POTRAŽNJA' key='2'>
                  <div className='table'>
                    <div style={{ textAlign: 'center' }}>
                      {demand.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
                      {!demand.isLoading && demand.data && demand.data.items && demand.data.items.length > 0 && (
                        <TableDemand
                          data={tableDataDemand}
                          deleteHandler={deleteDemandHandler}
                          columnKeys={updatedColumnKeysDemand}
                          title='Data'
                          editPath='/admin/edit-demand/'
                          viewPath='/admin/demand-view/'
                        />
                      )}
                      {!demand.isLoading && demand.data && demand.data.items && demand.data.items.length === 0 && (
                        <div className='no-data-box'>
                          <h2>Nema podataka</h2>
                        </div>
                      )}
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </Content>
          </Layout>
        )}
      </div>
    </div>
  );
};

export default ViewCommissioner;
