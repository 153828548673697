import React, { useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification, Divider } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { DataForm } from '../../components/forms';

const EditDataContract = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', {}, currentuser.data.token, 'get');
  const [clients, fetchClients] = useAxios('', {}, currentuser.data.token, 'get');
  const [coOwners, fetchCoOwners] = useAxios('', {}, currentuser.data.token, 'get');
  const [commissioners, fetchCommissioners] = useAxios('', {}, currentuser.data.token, 'get');
  const [locations, fetchLocations] = useAxios('', {}, currentuser.data.token, 'get');
  const [structures, fetchStructures] = useAxios('', {}, currentuser.data.token, 'get');
  const { dataId } = props.match.params;
  let clientsData = clients.data;
  let coOwnersData = coOwners.data;
  let commissionersData = commissioners.data;
  let locationsData = locations.data;

  let structuresData = structures.data;
  useEffect(() => {
    fetchData(`${SERVER_URL}/data`, []);
    fetchClients(
      `${SERVER_URL}/clients?filter=` +
        encodeURIComponent(
          JSON.stringify({
            clientType: { $in: ['prodavac'] },
          }),
        ),
      [],
    );
    fetchCoOwners(
      `${SERVER_URL}/clients?filter=` +
        encodeURIComponent(
          JSON.stringify({
            clientType: { $in: ['suvlasnik'] },
          }),
        ),
      [],
    );
    fetchCommissioners(
      `${SERVER_URL}/clients?filter=` +
        encodeURIComponent(
          JSON.stringify({
            clientType: { $in: ['punomoćnik'] },
          }),
        ),
      [],
    );
    fetchLocations(`${SERVER_URL}/locations`, []);
    fetchStructures(`${SERVER_URL}/structures`, []);
    if (dataId) fetchData(`${SERVER_URL}/data/${dataId}`, {});
  }, [
    fetchData,
    fetchClients,
    fetchCoOwners,
    fetchCommissioners,
    fetchLocations,
    fetchStructures,
    dataId,
    clientsData,
    locationsData,
    structuresData,
    coOwnersData,
    commissionersData,
  ]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/data` : `${SERVER_URL}/data/${dataId}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Nekretnina ${isNew ? 'je kreirana.' : 'je ažurirana.'}`,
        placement: 'bottomRight',
      });
      if (isNew) {
        history.push('/admin/data-contract');
        window.location.reload();
      }
      if (!isNew) {
        history.push(`/admin/view-data-contract/${dataId}`);
        window.location.reload();
      }
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = !data.isLoading && data.data && currentuser.language;
  const isClientsFetched = !clients.isLoading && clientsData.items;
  const isCoOwnersFetched = !coOwners.isLoading && coOwnersData.items;
  const isCommissionersFetched = !commissioners.isLoading && commissionersData.items;
  const isLocationsFetched = !locations.isLoading && locationsData.items;
  const isStructuresFetched = !structures.isLoading && structuresData.items;

  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Link to='/admin/data-contract'>
          <Button type='primary'>Nazad na nekretnine ugovori</Button>
        </Link>
      </div>
      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}

        {!dataId &&
          clientsData &&
          isDataFetched &&
          isClientsFetched &&
          isLocationsFetched &&
          isStructuresFetched &&
          isCoOwnersFetched &&
          isCommissionersFetched && (
            <DataForm
              isNew={true}
              clients={clientsData.items}
              coOwners={coOwnersData.items}
              commissioners={commissionersData.items}
              locations={locationsData.items}
              structures={structuresData.items}
              language={currentuser.language}
              onSubmit={onSubmit}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
            />
          )}

        {dataId &&
          !data.isError &&
          data.data &&
          isDataFetched &&
          isClientsFetched &&
          isLocationsFetched &&
          isStructuresFetched &&
          isCoOwnersFetched &&
          isCommissionersFetched && (
            <DataForm
              isNew={false}
              data={data.data}
              clients={clientsData.items}
              coOwners={coOwnersData.items}
              commissioners={commissionersData.items}
              locations={locationsData.items}
              structures={structuresData.items}
              language={currentuser.language}
              onSubmit={onSubmit}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
            />
          )}
      </div>
    </div>
  );
};

export default EditDataContract;
