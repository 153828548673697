import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { notification, Button, Input } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { Table } from '../../components/tables';
import ExportExcel from '../../components/csv/ExportExcel';
import { SERVER_URL } from '../../config';
import EditUserModal from '../../components/modals/EditModal';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'address',
  'zip',
  'taxId',
  'phone',
  'legalType',
  'wishList',
  'country',
  'companyName',
  'createdAt',
  'updatedAt',
  'avatar',
  'city',
  'companyZip',
  'companyPhone',
  'companyAddress',
];

const Users = () => {
  const currentuser = useContext(UserContext);
  const [users, fetchUsers] = useAxios('', [], currentuser.data.token, 'get');
  const [showModal, setShowModal] = useState();
  const [search, setSearch] = useState();
  const [useri, setUseri] = useState();
  const [csvUsers, setCsvUsers] = useState(undefined);

  useEffect(() => {
    fetchUsers(`${SERVER_URL}/users`, []);
  }, [fetchUsers]);

  useEffect(() => {
    let newUsersData = [];
    if (users.data.items) {
      for (const user of users.data.items) {
        user.phone = user.phone[0];
        newUsersData.push(user);
      }
    }
    setCsvUsers(newUsersData);
  }, [users]);

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = showModal ? 'hidden' : 'auto';
  }, [showModal]);

  //Moze da se pretrazuje po imenu, prezimenu, role, status, grad,email
  //Nalazi se odredjivanje toga u user.controllers.js u searchUsers

  useEffect(() => {
    if (search) {
      Axios.get(`${SERVER_URL}/users/search/${search}`)
        .then((res) => {
          setUseri(res.data.users);
        })
        .catch((err) => console.log(err));
    } else {
      setUseri([]);
    }
  }, [search, currentuser]);

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Korisnik je obrisan.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem pri brisanju. Molimo pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };
  let columnKeys;
  if (users.data.items && users.data.items.length > 0) {
    const keys = Object.keys(users.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (search) {
    if (useri && useri.length > 0) {
      tableData = useri.map((item) => {
        if (Array.isArray(item.role)) item.role = item.role.join(', ');
        item.createdAt = new Date(item.createdAt).toLocaleString();
        item.updatedAt = new Date(item.updatedAt).toLocaleString();
        if (item.status === 'ACTIVE') item.status = 'AKTIVAN';
        if (item.status === 'SUSPENDED') item.status = 'SUSPENDOVAN';
        if (item.status === 'DELETED') item.status = 'OBRISAN';
        if (item.status === 'WAITING_FOR_ACTIVATION') item.status = 'ČEKA AKTIVACIJU';
        return item;
      });
    }
  } else if (users.data.items && users.data.items.length > 0) {
    tableData = users.data.items.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      if (item.status === 'ACTIVE') item.status = 'AKTIVAN';
      if (item.status === 'SUSPENDED') item.status = 'SUSPENDOVAN';
      if (item.status === 'DELETED') item.status = 'OBRISAN';
      if (item.status === 'WAITING_FOR_ACTIVATION') item.status = 'ČEKA AKTIVACIJU';
      return item;
    });
  }

  return (
    <div className='table usersWrapper'>
      <div className='actions-block' style={{ marginBottom: '8px' }}>
        <ExportExcel
          csvData={csvUsers != null && csvUsers.length >= 1 ? csvUsers : []}
          fileName={`Users export ` + new Date().toLocaleString()} // Ime xlsx fajla
          propsToRemove={[
            '_id',
            'objectId',
            'userId',
            'url',
            'description',
            'updatedAt',
            '__v',
            'createdAt',
            'zip',
            'taxId',
            'legalType',
            'wishList',
            'role',
            'status',
            'avatar',
          ]} // Opcija 1 - uklanjaju se polja koje ne treba da se exportuju
          // format="products" // Opcija 2 - custom formatiranje - treba po imenu format polja u exportExcel.js formatirati polja po zelji
        />
        {/*<Link to='/admin/new-user'>*/}
        <div className='desna-strana'>
          <Button onClick={() => setShowModal(!showModal)} type='primary'>
            Kreiranje korisnika
          </Button>
          {/*</Link>*/}
        </div>
      </div>

      <div style={{ textAlign: 'center' }}>
        {users.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!users.isLoading && users.data.items && users.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteUserHandler}
            columnKeys={columnKeys}
            title='User'
            editPath='/admin/edit-user/'
            viewPath='/admin/view-user/'
          />
        )}
        {!users.isLoading && users.data.items && users.data.items.length === 0 && (
          <div className='no-data-box'>
            <h2>Nema podataka</h2>
          </div>
        )}
      </div>
      <EditUserModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default Users;
