import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button, Form, Select, Row, Input } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/DataContractTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'heating',
  'webDesc',
  'privDesc',
  'featureImage',
  'flag',
  'views',
  'currentDate',
  'notCurrentDate',
  'gallery',
  'privGallery',
  'comments',
  'user',
  'createdAt',
  'updatedAt',
  'sold',
  'structure',
  'isExpiredContract',
  'isMailSent',
  'client',
  'location',
  'createdByUser',
  'soldByUser',
  'rented',
  'postedCetiriZida',
  'clientGaveUp',
  'googleDescription',
  'createdByUserDate',
  'soldByUserDate',
  'postedAt',
  'coOwners',
  'ownerSold',
  'url',
  'cadastralTownship',
  'commissioner',
  'invoicedAmount',
];

const DataNotActive = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [locations, fetchLocations] = useAxios('', [], currentuser.data.token, 'get');
  const locationsData = locations.data;
  const [filterParams, setFilterParams] = useState({});
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData(
      `${SERVER_URL}/data?filter=` +
        encodeURIComponent(
          JSON.stringify({
            $or: [{ sold: true }, { rented: true }, { clientGaveUp: true }, { ownerSold: true }],
          }),
        ),
      [],
    );
    fetchLocations(`${SERVER_URL}/locations`);
  }, [fetchData, fetchLocations]);
  useEffect(() => {
    const filter = JSON.parse(sessionStorage.getItem('filterDataNotActive'));

    if (filter) {
      if (filter.realEstateId) {
        form.setFieldsValue({
          realEstateId: filter.realEstateId,
        });
      }
      if (filter.location) {
        form.setFieldsValue({
          location: filter.location,
        });
      }
      if (filter.type) {
        form.setFieldsValue({
          type: filter.type,
        });
      }
      if (filter.category) {
        form.setFieldsValue({
          category: filter.category,
        });
      }
      if (filter.category) {
        form.setFieldsValue({
          category: filter.category,
        });
      }
      if (filter.price) {
        if (filter.price.$gte) {
          form.setFieldsValue({
            priceFrom: filter.price.$gte,
          });
        }
        if (filter.price.$lte) {
          form.setFieldsValue({
            priceTo: filter.price.$lte,
          });
        }
      }
      if (filter.squareMeter) {
        if (filter.squareMeter.$gte) {
          form.setFieldsValue({
            squareFrom: filter.squareMeter.$gte,
          });
        }
        if (filter.squareMeter.$lte) {
          form.setFieldsValue({
            squareTo: filter.squareMeter.$lte,
          });
        }
      }
    }

    fetchData(
      `${SERVER_URL}/data?filter=` +
        encodeURIComponent(
          JSON.stringify({
            $or: [{ sold: true }, { rented: true }, { clientGaveUp: true }, { ownerSold: true }],
            ...filter,
          }),
        ),
      [],
    );
  }, []);

  useEffect(() => {
    const filter = sessionStorage.getItem('filterDataNotActive');

    fetchData(
      `${SERVER_URL}/data?filter=` +
        encodeURIComponent(
          JSON.stringify({
            $or: [{ sold: true }, { rented: true }, { clientGaveUp: true }, { ownerSold: true }],
            ...JSON.parse(filter),
          }),
        ),
      [],
    );
  }, [filterParams]);

  const onFinish = (values) => {
    const filter = {};

    if (values.priceTo || values.priceFrom) filter.price = {};
    if (values.squareTo || values.squareFrom) filter.squareMeter = {};

    if (values.priceFrom) filter.price.$gte = Number(values.priceFrom);
    if (values.priceTo) filter.price.$lte = Number(values.priceTo);
    if (values.squareFrom) filter.squareMeter.$gte = Number(values.squareFrom);
    if (values.squareTo) filter.squareMeter.$lte = Number(values.squareTo);
    if (values.location) filter.location = values.location;
    if (values.category) filter.category = values.category;
    if (values.type) filter.type = values.type;
    if (values.realEstateId) filter.realEstateId = Number(values.realEstateId);

    sessionStorage.setItem('filterDataNotActive', JSON.stringify(filter));
    setFilterParams(filter);
  };
  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/data/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Nekretnina je obrisana.',
        placement: 'bottomRight',
      });
      window.location.reload();
    } catch (err) {
      if (err.response && err.response.status === 400) {
        notification.error({
          message: err.response.data.message,
          placement: 'bottomRight',
        });
      } else {
        notification.error({
          message: 'Problem pri brisanju. Molimo pokušajte ponovo.',
          placement: 'bottomRight',
        });
      }
    }
  };

  let columnKeys;
  let updatedColumnKeys;
  const desiredKeysClient = ['clientName'];
  const desiredKeysLocation = ['locationName'];
  if (data.data && data.data.items && data.data.items.length > 0) {
    let firstItem = data.data.items[0];
    for (const item of data.data.items) {
      console.log(item);
      if (item.client !== undefined && item.client !== null && item.location !== undefined && item.location !== null) {
        // Found the desired item, create an object with all properties
        firstItem = { ...item };
        break; // Exit the loop after finding the first matching item
      }
    }

    const keys = Object.keys(firstItem);

    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    updatedColumnKeys = columnKeys;

    if (firstItem.client !== undefined && firstItem.client !== null) {
      const clientKeys = Object.keys(firstItem.client).filter((key) => desiredKeysClient.includes(key));

      updatedColumnKeys = [...new Set(updatedColumnKeys.concat(clientKeys))];
    }

    if (firstItem.location !== undefined && firstItem.location !== null) {
      const locationKeys = Object.keys(firstItem.location).filter((key) => desiredKeysLocation.includes(key));

      updatedColumnKeys = [...new Set(updatedColumnKeys.concat(locationKeys))];
    }
  }

  let tableData = [];

  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();

      const regex = /^(\d{4})-(\d{2})-(\d{2}).*/;
      const match = item.contractDate !== null && item.contractDate.match(regex);

      if (match) {
        const [, year, month, day] = match;
        const date = `${day}.${month}.${year}.`;
        item.contractDate = date;
      }

      item.contractDate = item.contractDate || 'Nema podatka';

      item.locationName = item.location?.locationName || 'Nema podatka';
      item.clientName = item.client?.clientName || 'Nema podatka';

      item.realEstateId = item.realEstateId;
      if (item.published === true) item.published = 'Da';
      if (item.published === false) item.published = 'Ne';
      return item;
    });
  }
  const isLocationsFetched = !locations.isLoading && locationsData.items;
  const restartFilter = () => {
    form.resetFields();
    sessionStorage.removeItem('filterDataNotActive');
    setFilterParams({});
  };
  return (
    <>
      <div className='table'>
        <h2 className='data-title' style={{ paddingBottom: '15px', paddingLeft: '15px', textAlign: 'left' }}>
          Nekretnine neaktivne
        </h2>
        <div className='statistic-block'>
          <h3>Filter:</h3>

          <Form className='form-horizontal' onFinish={(values) => onFinish(values)} layout='horizontal' form={form}>
            <Row type='flex' gutter={0}>
              <Form.Item label='Id' name='realEstateId'>
                <Input type='number' style={{ width: '150px', height: '32px' }} />
              </Form.Item>
              {isLocationsFetched && (
                <Form.Item label='Lokacija' name='location'>
                  <Select
                    virtual={false}
                    allowClear='true'
                    showSearch
                    placeholder='Pretražite lokacije...'
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                      option.props.children
                        .normalize('NFD') // Normalize diacritic characters
                        .toLowerCase()
                        .indexOf(input.normalize('NFD').toLowerCase()) >= 0
                    }
                    style={{ width: '150px' }}
                  >
                    {locations.data.items.map((location) => (
                      <Option key={location._id} value={location._id}>
                        {location.locationName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              <Form.Item label='Vrsta' name='type'>
                <Select virtual={false} allowClear='true' placeholder='Izaberi vrstu' style={{ width: '150px' }}>
                  {[
                    'kuća',
                    'stan',
                    'garsonjera',
                    'poslovni prostor',
                    'vikendica',
                    'plac',
                    'zemlja',
                    'garaža',
                    'salaš',
                    'voćnjak/vinograd',
                  ].map((option, key) => (
                    <Option key={key} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label='Kategorija' name='category'>
                <Select virtual={false} allowClear='true' placeholder='Izaberi kategoriju' style={{ width: '150px' }}>
                  {['prodaja', 'prodaja/zamena', 'izdavanje'].map((option, key) => (
                    <Option key={key} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label='Cena od' name='priceFrom'>
                <Input type='number' style={{ width: '150px', height: '32px' }} suffix='€' />
              </Form.Item>
              <Form.Item label='Cena do' name='priceTo'>
                <Input type='number' style={{ width: '150px', height: '32px' }} suffix='€' />
              </Form.Item>
              <Form.Item label='Kvadratura od' name='squareFrom'>
                <Input type='number' style={{ width: '150px', height: '32px' }} suffix='m²' />
              </Form.Item>
              <Form.Item label='Kvadratura do' name='squareTo'>
                <Input type='number' style={{ width: '150px', height: '32px' }} suffix='m²' />
              </Form.Item>

              <Button type='primary' htmlType='submit'>
                Pretraži
              </Button>
              {sessionStorage.getItem('filterDataNotActive') && (
                <Button type='button' onClick={restartFilter}>
                  Obriši filter
                </Button>
              )}
            </Row>
          </Form>
        </div>
        <div style={{ textAlign: 'center' }}>
          {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
          {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
            <Table
              data={tableData}
              deleteHandler={deleteDataHandler}
              columnKeys={updatedColumnKeys}
              title='Data'
              editPath='/admin/edit-data-not-active/'
              viewPath='/admin/view-data-not-active/'
            />
          )}
          {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && (
            <div className='no-data-box'>
              <h2>Nema podataka</h2>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DataNotActive;
