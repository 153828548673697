import React, { useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification, Divider } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import ClientForm from '../../components/forms/ClientForm';

const NewCoOwner = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);

  const onSubmit = async (formData) => {
    const method = 'post';
    const route = `${SERVER_URL}/clients`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Klijent je kreiran.',
        placement: 'bottomRight',
      });

      history.push('/admin/clients-coowners');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;

      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Link to='/admin/clients-coowners'>
          <Button type='primary'>Nazad na suvlasnike</Button>
        </Link>
      </div>
      <div>
        <ClientForm
          isNew={true}
          language={currentuser.language}
          onSubmit={onSubmit}
          SERVER_URL={SERVER_URL}
          token={currentuser.data.token}
        />
      </div>
    </div>
  );
};

export default NewCoOwner;
