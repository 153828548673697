import React from 'react';
import { Form, Input, Button, Card, notification } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { Link } from 'react-router-dom';

const { Meta } = Card;

const backgroundStyle = {
  width: '100vw',
  height: '100vh',
  backgroundImage: `url(/login-bcg.jpg)`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const companyLogoStyle = {
  maxWidth: '250px',
  marginBottom: '30px',
};

const ForgotPassword = () => {
  const onFinish = async (values) => {
    try {
      const sendPasswordLink = await Axios.post(
        `${SERVER_URL}/forgot-dashboard-password`,
        { email: values.email },
        { withCredentials: false },
      );
      if (sendPasswordLink.data.status) {
        notification.success({
          message: sendPasswordLink.data.status,
          placement: 'bottomRight',
        });
      }
    } catch (err) {
      notification.warn({
        message: err.response.data.message,
        placement: 'bottomRight',
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='log' style={backgroundStyle}>
      <div className='card-wrapper'>
        <Card className='login-header' bordered={false}>
          <img style={companyLogoStyle} className='login-logo' src='/logoD.png' alt='Universal Logo' />
          <Meta title='Unesite Vaš e-mail' />
          <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item name='email' rules={[{ required: true, message: 'Molimo unesite Vaš e-mail!' }]}>
              <Input placeholder='E-mail' />
            </Form.Item>

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button type='primary' style={{ width: '100%' }} htmlType='submit'>
                Pošalji
              </Button>
            </Form.Item>

            <Form.Item className='link' {...tailLayout}>
              <Link to='/login' className='link-forgot-password'>
                <span></span>
                <span className='link-primary'>Prijavi se</span>
              </Link>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ForgotPassword;
