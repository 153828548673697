import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Select, Result, Radio, notification, Modal } from 'antd';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';
import { DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';

const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 },
};

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const initialValues = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  password: undefined,
  phone: '',
  avatar: undefined,
  role: [],
};

const UserForm = ({
  data,
  updateHandler,
  createHandler,
  result,
  setResult,
  SERVER_URL,
  token,
  showModal,
  setShowModal,
  profile,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [legalType, setLegalType] = useState(data ? data.legalType : 'PRIVATE');
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  let user = data ? { ...data } : initialValues;
  const isNew = data ? false : true;
  const [promptName] = useState(); //izbrisano setPromptName
  const [deletePop, setDeletePop] = useState();

  useEffect(() => {
    if (data && data.avatar) setImage(data.avatar.url);
  }, [data]);

  const onFinish = (values) => {
    if (isNew) {
      createHandler(values);
    } else {
      updateHandler(values);
    }
    setShowModal(!showModal);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleLegalType = (e) => {
    setLegalType(e.target.value);
    form.setFieldsValue({ legalType: e.target.value });
  };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    setImage(null);
    form.setFieldsValue({ avatar: null });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Korisnik je obrisan.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        if (id === currentuser.data.id) {
          sessionStorage.removeItem('user');
          currentuser.setLoggedIn(false);
          history.push('/login');
        }
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molimo pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  // const onClickHandler = () => {
  //   setShowModal(!showModal);
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 2000);
  // };    dodati onClickHandler dole (izbrisan na Add user button-u)

  return (
    <>
      <div className='dashboard'>
        <div className='panel panel-body'>
          {/* eslint-disable-next-line no-useless-concat */}
          {/* <Card title={`${isNew ? 'New' : 'Edit'} user`} bordered={false}> */}
          {/* <CloseOutlined className='x-btn' onClick={() => setShowModal(false)} /> */}
          {/* {profile && (
              <div className='delete-user' onClick={() => setDeletePop(!deletePop)}>
                Delete user <DeleteOutlined />
              </div>
            )} */}
          {!result && (
            <Form
              {...layout}
              name='basic'
              initialValues={user}
              onFinish={(values) => onFinish(values, isNew)}
              onFinishFailed={onFinishFailed}
              layout='horizontal'
              form={form}
            >
              <Form.Item /*label='Avatar'*/ className='upload-wrapper right' name='avatar'>
                <UploadBox
                  editHandler={avatarHandler}
                  deleteHandler={deleteAvatarHandler}
                  image={image}
                  index={0}
                  name='avatar'
                />
              </Form.Item>
              {/* <Form.Item name='legalType' >
                <Radio.Group onChange={handleLegalType}>
                  <Radio value='PRIVATE'>Private</Radio>
                  <Radio value='COMPANY'>Firma</Radio>
                </Radio.Group>
              </Form.Item> */}

              <Form.Item
                /*label='Email'*/
                name='email'
                rules={[
                  {
                    required: true,
                    message: 'Molimo Vas unesite e-mail korisnika!',
                  },
                ]}
              >
                <Input placeholder='E-mail' />
              </Form.Item>

              <Form.Item
                /*label='First name'*/
                name='firstName'
                rules={[
                  {
                    required: true,
                    message: 'Molimo Vas unesite ime korisnika!',
                  },
                ]}
              >
                <Input placeholder='Ime' />
              </Form.Item>

              <Form.Item
                /*label='Last name'*/
                name='lastName'
                rules={[
                  {
                    required: true,
                    message: 'Molimo Vas unesite prezime korisnika!',
                  },
                ]}
              >
                <Input placeholder='Prezime' />
              </Form.Item>

              <Form.Item /*label='Phone'*/ name='phone'>
                <Input placeholder='Broj telefona' />
              </Form.Item>

              {isNew && (
                <Form.Item
                  /*label='Password'*/
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo Vas unesite šifru!',
                    },
                  ]}
                >
                  <Input.Password placeholder='Šifra' />
                </Form.Item>
              )}

              <Form.Item
                /*label='Role'*/
                name='role'
                rules={[
                  {
                    required: true,
                    message: 'Molimo Vas unesite ulogu korisnika!!',
                  },
                ]}
              >
                <Select
                  virtual={false}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{ offset: [0, -110] }}
                  mode='multiple'
                  placeholder='Uloga'
                >
                  <Select.Option value='admin'>Admin</Select.Option>
                  <Select.Option value='agent'>Agent</Select.Option>
                </Select>
              </Form.Item>

              {!isNew && (
                <Form.Item
                  /*label='Status'*/
                  name='status'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo Vas unesite status naloga korisnika!',
                    },
                  ]}
                >
                  <Select virtual={false} getPopupContainer={(trigger) => trigger.parentNode}>
                    <Select.Option value='ACTIVE'>AKTIVAN</Select.Option>
                    <Select.Option value='SUSPENDED'>SUSPENDOVAN</Select.Option>
                    <Select.Option value='DELETED'>OBRISAN</Select.Option>
                    <Select.Option value='WAITING_FOR_ACTIVATION'>ČEKA AKTIVACIJU</Select.Option>
                  </Select>
                </Form.Item>
              )}

              <Form.Item {...tailLayout}>
                <div>
                  <Button type='primary' htmlType='submit' style={{ float: 'right', marginLeft: '15px' }}>
                    {data ? 'Sačuvaj korisnika' : 'Dodaj korisnika'}
                  </Button>
                  <Button type='secondary' style={{ float: 'right' }} onClick={() => setShowModal(!showModal)}>
                    Otkaži
                  </Button>
                </div>
              </Form.Item>
            </Form>
          )}
          {result && (
            <Result
              title='Korisnik je kreiran i verifikacioni mail je poslat.'
              extra={
                <Button
                  type='primary'
                  key='console'
                  onClick={() => {
                    setResult(false);
                    window.location.reload();
                  }}
                >
                  Ok
                </Button>
              }
            />
          )}
          {/* </Card> */}

          {modal.visible && (
            <GalleryModal
              className='gallery-modal'
              visible={modal.visible}
              limit={modal.limit}
              formKey={modal.formKey}
              imageSavePath='public/images/users/'
              imageType='data'
              imageHeight={200}
              SERVER_URL={SERVER_URL}
              token={token}
              form={form}
              onCancel={() => setModal({ ...modal, visible: false })}
              onInsert={(values) => onInsert(values, modal.formKey)}
            />
          )}
          <Modal
            className='deleteModal'
            centered={true}
            visible={deletePop}
            onCancel={() => setDeletePop(!deletePop)}
            onOk={() => deleteUserHandler(user._id)}
            okText='Yes'
            cancelText='No'
          >
            <p>Da li ste sigurni da želite da obrišete?</p>
            {promptName && <p>{promptName.toUpperCase()}?</p>}
          </Modal>
        </div>
      </div>
    </>
  );
};

export default UserForm;
