import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { SERVER_URL } from '../config';
import { UserContext } from '../App';
import ViewUser from './users/ViewUser';
const Home = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  //FUNKCIJA ZA DODAVANJE LOKACIJA, NE DIRATI!

  // useEffect(() => {
  //   fetchLocationsFrom4zida();
  // }, []);
  // const fetchLocationsFrom4zida = async () => {
  //   try {
  //     const locationResponse = await Axios.get(`${SERVER_URL}/locations`);
  //   } catch (err) {
  //     console.log(err.response.data.message);
  //     return message.warning(err.response.data.message, 3);
  //   }
  // };

  //FUNKCIJA ZA DODAVANJE STRUKTURA, NE DIRATI!

  // useEffect(() => {
  //   fetchStruktureFrom4zida();
  // }, []);
  // const fetchStruktureFrom4zida = async () => {
  //   try {
  //     const structuresResponse = await Axios.get(`${SERVER_URL}/structures`);
  //   } catch (err) {
  //     console.log(err.response.data.message);
  //     return message.warning(err.response.data.message, 3);
  //   }
  // };
  useEffect(() => {
    // Redirect to a different path
    history.push(`/admin/view-user/${currentuser && currentuser?.data?.id}`);
  }, []);
  return (
    <div className='table'>
      <div className='actions-block'></div>
      <h2 className='special-title'>Dobrodošli na Dashboard!</h2>
    </div>
  );
};

export default Home;
