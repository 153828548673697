import React, { useEffect, useState } from 'react';
import Axios from 'axios';
// import slugify from 'slugify';
import { getTreeFromFlatData } from 'react-sortable-tree';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import JoditEditor from 'jodit-react';
import {
  Tabs,
  Divider,
  Input,
  Checkbox,
  Radio,
  Select,
  DatePicker,
  Button,
  Form,
  Row,
  Col,
  TreeSelect,
  Popconfirm,
  Space,
  notification,
} from 'antd';
import dayjs from 'dayjs';
import useAxios from '../../hooks/useAxios';
import UploadBox from '../base/UploadBox';
import DNDGallery from '../base/DNDGallery';
import DNDPrivGallery from '../base/DNDPrivGallery';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/sr_RS';
import { GalleryModal } from './';
import TextArea from 'antd/lib/input/TextArea';

const { Option } = Select;

/**
 * @constant {Integer} GALLERY_LIMIT - Max number of images in gallery
 */
const GALLERY_LIMIT = 100;

const configJodit = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  toolbarAdaptive: false,
  minHeight: '300',
  placeholder: 'Unesite opis...',
  buttons: [
    'source',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    'eraser',
    '|',
    'superscript',
    'subscript',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    // 'font',
    'fontsize',
    '\n',
    'brush',
    'paragraph',
    'link',
    'align',
    // '|',
    'undo',
    'redo',
    'selectall',
    'cut',
    'copy',
    'paste',
    'copyformat',
    '|',
    'hr',
    'symbol',
    'fullsize',
    'print',
    'preview',
    'find',
  ],
};

const formInit = {
  _id: null,
  location: null,
  structure: null,
  client: null,
  address: '',
  city: '',
  flag: [],
  // price: 0,
  // landSurface: 0,
  // squareMeter: 0,
  plotNumber: '',
  rooms: 0,
  sold: false,
  zkNumber: '',
  heating: 'nema',
  featureImage: undefined,
  gallery: [],
  privGallery: [],
  published: false,
  isExpiredContract: false,
  isMailSent: false,
  rented: false,
  ownerSold: false,
  postedCetiriZida: false,
  clientGaveUp: false,
};

const flags = [
  { label: 'izdvajamo', value: 'izdvajamo' },
  { label: 'povoljno', value: 'povoljno' },
];

const category = [
  { label: 'prodaja', value: 'prodaja' },
  { label: 'prodaja/zamena', value: 'prodaja/zamena' },
  { label: 'izdavanje', value: 'izdavanje' },
];

const type = [
  { label: 'kuća', value: 'kuća' },
  { label: 'stan', value: 'stan' },
  { label: 'garsonjera', value: 'garsonjera' },
  { label: 'poslovni prostor', value: 'poslovni prostor' },
  { label: 'vikendica', value: 'vikendica' },
  { label: 'plac', value: 'plac' },
  { label: 'zemlja', value: 'zemlja' },
  { label: 'garaža', value: 'garaža' },
  { label: 'salaš', value: 'salaš' },
  { label: 'voćnjak / vinograd', value: 'voćnjak/vinograd' },
];

const rooms = Array.from({ length: 16 }, (_, index) => (
  <Radio key={index} value={index}>
    {index}
  </Radio>
));

const heating = [
  { label: 'bez', value: 'bez' },
  { label: 'termo akumulaciono', value: 'termo akumulaciono' },
  { label: 'etažno', value: 'etažno' },
  { label: 'gas', value: 'gas' },
  { label: 'toplana', value: 'toplana' },
  { label: 'čvrsto gorivo', value: 'čvrsto gorivo' },
  { label: 'struja', value: 'struja' },
  { label: 'norveški radijatori', value: 'norveški radijatori' },
  { label: 'podno grejanje', value: 'podno grejanje' },
];

const DataFrom = ({
  isNew,
  data,
  clients,
  coOwners,
  commissioners,
  locations,
  structures,
  language,
  onSubmit,
  SERVER_URL,
  token,
}) => {
  const [form] = Form.useForm();
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [checkedList, setCheckedList] = useState();
  const [isRequired, setIsRequired] = useState(false);
  const [clientType, setClientType] = useState('seller');
  const [lessors, setLessors] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);

  if (data) ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete data[key]);

  let initialValues = { ...formInit, ...data };

  // const [image, setImage] = useState(initialValues.featureImage ? initialValues.featureImage.url : undefined);
  const [primaryImage, setPrimaryImage] = useState(initialValues.featureImage ? initialValues.featureImage : undefined);
  const [currentGallery, setCurrentGallery] = useState(initialValues.gallery?.length > 0 ? initialValues.gallery : []);
  const [currentPrivGallery, setCurrentPrivGallery] = useState(
    initialValues.privGallery?.length > 0 ? initialValues.privGallery : [],
  );

  if (data) {
    if (data.currentDate) form.setFieldsValue({ currentDate: moment.utc(data.currentDate) });
    if (data.notCurrentDate) form.setFieldsValue({ notCurrentDate: moment.utc(data.notCurrentDate) });
    if (data.contractDate) form.setFieldsValue({ contractDate: moment.utc(data.contractDate) });
  }
  useEffect(() => {
    if (data) {
      setClientType(data.clientType ? data.clientType : 'seller');

      if (data && data.clientType) {
        fetchLessors();
      }

      if (data.featuredAdUntil) form.setFieldsValue({ featuredAdUntil: dayjs(data.featuredAdUntil) });
      if (data.client) {
        form.setFieldsValue({
          client: data.client._id,
        });
        form.setFields([
          {
            name: 'client',
            placeholder: `${data.client.nid} ${data.client.clientName}`,
          },
        ]);
      }
      if (data.commissioner) {
        form.setFieldsValue({
          commissioner: data.commissioner._id,
        });
        form.setFields([
          {
            name: 'commissioner',
            placeholder: `${data.commissioner.nid} ${data.commissioner.clientName}`,
          },
        ]);
      }
      if (data.coOwners) {
        const initialValues = data.coOwners.map((coOwner) => ({
          coowner: coOwner.coowner._id,
        }));

        // Set the initial values for the coOwners in the Form.List
        form.setFieldsValue({ coOwners: initialValues });

        // Loop through each coOwner in data.coOwners and set placeholders for the Select field
        data.coOwners.forEach((coOwner, index) => {
          form.setFields([
            {
              name: ['coOwners', index, 'coowner'],
              placeholder: `${coOwner.coowner.nid} ${coOwner.coowner.clientName}`,
            },
          ]);
        });
      }
      if (data.location) {
        form.setFieldsValue({
          location: data.location._id,
        });
        form.setFields([
          {
            name: 'location',
            placeholder: `${data.location.locationName}`,
          },
        ]);
      }
      if (data.structure) {
        form.setFieldsValue({
          structure: data.structure._id,
        });
        form.setFields([
          {
            name: 'structure',
            placeholder: `${data.structure.structureName}`,
          },
        ]);
      }
      setRequired(data.structure ? data.structure._id : null);
    }
  }, []);

  const onFinish = async (values, isNew) => {
    values.featureImage = primaryImage;
    values.gallery = currentGallery;
    values.privGallery = currentPrivGallery;
    values.clientType = clientType;
    if (values.contractDate === null) {
      values.contractDate = '';
    }
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  /**
   * Open feature image modal
   */
  const editImageTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'featureImage' });
  };

  /**
   *  Delete feature Image
   */
  const deleteFeatureImageHandler = () => {
    setPrimaryImage(null);

    form.setFieldsValue({ featureImage: null });
  };

  /**
   * Open gallery modal
   */
  const editGalleryTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'gallery', limit: GALLERY_LIMIT });
  };
  /**
   * Open priv gallery modal
   */
  const editPrivGalleryTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'privGallery', limit: GALLERY_LIMIT });
  };

  /**
   * Open gallery modal single image edit
   */
  const editGallerySingleImageTrigger = (index, id) => {
    setModal({ ...modal, visible: true, formKey: 'gallery', limit: 1, index, id });
  };

  /**
   * Open priv gallery modal single image edit
   */
  const editPrivGallerySingleImageTrigger = (index, id) => {
    setModal({ ...modal, visible: true, formKey: 'privGallery', limit: 1, index, id });
  };
  /**
   * Delete image in gallery by id
   * @param {String} id
   */
  const deleteGalleryImageHandler = (id) => {
    const newGallery = currentGallery.filter((image) => image._id !== id);
    setCurrentGallery(newGallery);
    form.setFieldsValue({ gallery: newGallery });
  };
  /**
   * Delete image in priv gallery by id
   * @param {String} id
   */
  const deletePrivGalleryImageHandler = (id) => {
    const newGallery = currentPrivGallery.filter((image) => image._id !== id);
    setCurrentPrivGallery(newGallery);
    form.setFieldsValue({ privGallery: newGallery });
  };
  /**
   * Insert image/images in form by form key
   * @param {[Object]} values
   * @param {String} formKey
   */
  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    if (modal.formKey === 'gallery') {
      setCurrentGallery(values);
    }
    if (modal.formKey === 'privGallery') {
      setCurrentPrivGallery(values);
    }
    if (modal.formKey === 'featureImage') {
      setPrimaryImage(values);
    }
  };

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < flags.length);
  };

  const onFinishFailed = (values) => {
    console.log(values);
    const emptyFields = values.errorFields.map(
      (errorField) =>
        `${
          errorField.name[0] === 'featureImage'
            ? 'Glavna slika'
            : errorField.name[0] === 'gallery'
            ? 'Galerija Na Sajtu'
            : errorField.name[0] === 'category'
            ? 'Kategorija'
            : errorField.name[0] === 'type'
            ? 'Vrsta'
            : errorField.name[0] === 'structure'
            ? 'Struktura'
            : errorField.name[0] === 'city'
            ? 'Grad'
            : errorField.name[0] === 'location'
            ? 'Lokacija'
            : errorField.name[0] === 'squareMeter'
            ? 'Kvadratura'
            : errorField.name[0] === 'price'
            ? 'Cena'
            : errorField.name[0]
        } - ${errorField.errors[0]} ${
          errorField.name[1]
            ? `Jezika: ${
                errorField.name[1] === 'sr'
                  ? 'srpski'
                  : errorField.name[1] === 'en'
                  ? 'engleski'
                  : errorField.name[1] === 'hu'
                  ? 'mađarski'
                  : ''
              }`
            : ''
        }`,
    );
    console.log(emptyFields);
    const uniqueFields = [...new Set(emptyFields)];
    console.log(uniqueFields);
    if (uniqueFields.length > 0) {
      const notificationContent = uniqueFields.map((field) => (
        <div key={field} style={{ color: 'red' }}>
          {field}
        </div>
      ));
      notification.open({
        message: 'Obavestenja o poljima koja nisu popunjena',
        description: notificationContent,
        placement: 'bottomRight',
      });
      return; // Stop further execution
    }
  };
  const setRequired = async (id) => {
    if (id) {
      const method = 'get';
      const route = `${SERVER_URL}/structures/${id}`;
      try {
        const response = await Axios[method](route);
        if (response.data.structureName === 'Plac') {
          setIsRequired(true);
        } else {
          setIsRequired(false);
        }
      } catch (error) {
        const msg = error.response ? error.response.data.message : error.message ? error.message : error;
        notification.error({
          message: msg,
          placement: 'bottomRight',
        });
      }
    } else {
      setIsRequired(false);
    }
  };

  const fetchLessors = async () => {
    try {
      const lessorsarr = await Axios.get(`${SERVER_URL}/clients?filter={ "clientType": "zakupodavac"}`);

      setLessors(lessorsarr.data.items);
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageDesc = (value, imageId) => {
    const image = primaryImage;
    if (imageId === image._id) {
      image.pictureDesc = value;
    }

    setPrimaryImage(image);
  };

  const handleSetClientType = (e) => {
    if (e === 'lessors') {
      fetchLessors();
    }

    setClientType(e);

    form.setFieldsValue({ client: null });
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          onFinishFailed={(values) => onFinishFailed(values)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={15}>
              <div className='panel-body'>
                <Tabs className='custom-tabs-container' tabPosition='top' defaultActiveKey={language.default.code}>
                  {language.list.map((lang) => (
                    <Tabs.TabPane
                      tab={
                        lang.code === 'sr'
                          ? 'srpski'
                          : lang.code === 'hu'
                          ? 'mađarski'
                          : lang.code === 'en'
                          ? 'engleski'
                          : ''
                      }
                      key={lang.code}
                      forceRender
                    >
                      <Row type='flex' gutter={16}>
                        <Col span={24}>
                          <Form.Item label='Flags' name='flag'>
                            <Checkbox.Group
                              // style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}
                              value={checkedList}
                              onChange={onChange}
                            >
                              {flags.map((option) => (
                                <Checkbox key={option.value} value={option.value}>
                                  {option.label}
                                </Checkbox>
                              ))}
                            </Checkbox.Group>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label='Evidenctioni broj (EB)' name='eb'>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row type='flex' gutter={16}>
                        <Col span={8}>
                          <Form.Item label='Tip klijenta' className='data-form__coowner-name'>
                            <Select
                              value={clientType}
                              onChange={(e) => {
                                handleSetClientType(e);
                              }}
                            >
                              <Option value='seller'>Prodavac</Option>
                              <Option value='lessors'>Zakupodavac</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row type='flex' gutter={16}>
                        <Col span={8}>
                          {clientType === 'seller' ? (
                            <Form.Item label='Prodavac' name='client' className='data-form__coowner-name'>
                              <Select
                                virtual={false}
                                allowClear='true'
                                showSearch
                                placeholder='Pretražite prodavca/klijenta...'
                                optionFilterProp='children'
                                filterOption={(input, option) =>
                                  option.props.children
                                    .normalize('NFD') // Normalize diacritic characters
                                    .toLowerCase()
                                    .indexOf(input.normalize('NFD').toLowerCase()) >= 0
                                }
                              >
                                {clients.map((client) => (
                                  <Option
                                    key={client.nid}
                                    value={client._id}
                                  >{`${client.nid} ${client.clientName}`}</Option>
                                ))}
                              </Select>
                            </Form.Item>
                          ) : (
                            <Form.Item label='Zakupodavac' name='client' className='data-form__coowner-name'>
                              <Select
                                virtual={false}
                                allowClear='true'
                                showSearch
                                placeholder='Pretražite zakupodavce...'
                                optionFilterProp='children'
                                // filterOption={(input, option) =>
                                //   option.props.children
                                //     .normalize('NFD') // Normalize diacritic characters
                                //     .toLowerCase()
                                //     .indexOf(input.normalize('NFD').toLowerCase()) >= 0
                                // }
                              >
                                {lessors &&
                                  lessors.length > 0 &&
                                  lessors.map((client) => (
                                    <Option
                                      key={client.nid}
                                      value={client._id}
                                    >{`${client.nid} ${client.clientName}`}</Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          )}
                        </Col>
                      </Row>

                      <Row type='flex' gutter={16}>
                        <Col span={8}>
                          <h4>Suvlasnici</h4>
                          <Form.List name='coOwners'>
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, ...restField }) => (
                                  <Space key={key} align='baseline'>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'coowner']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Ovo polje je obavezno.',
                                        },
                                      ]}
                                      className='data-form__coowner-name'
                                    >
                                      <Select
                                        virtual={false}
                                        allowClear='true'
                                        showSearch
                                        placeholder='Pretražite suvlasnika...'
                                        optionFilterProp='children'
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .normalize('NFD') // Normalize diacritic characters
                                            .toLowerCase()
                                            .indexOf(input.normalize('NFD').toLowerCase()) >= 0
                                        }
                                      >
                                        {coOwners.map((client) => (
                                          <Option
                                            key={client.nid}
                                            value={client._id}
                                          >{`${client.nid} ${client.clientName}`}</Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                  </Space>
                                ))}

                                <Form.Item>
                                  <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                    Dodaj suvlasnika
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </Col>
                      </Row>
                      <Row type='flex' gutter={16}>
                        <Col span={8}>
                          <Form.Item label='Punomoćnik' name='commissioner' className='data-form__coowner-name'>
                            <Select
                              virtual={false}
                              allowClear='true'
                              showSearch
                              placeholder='Pretražite punomoćnike...'
                              optionFilterProp='children'
                              filterOption={(input, option) =>
                                option.props.children
                                  .normalize('NFD') // Normalize diacritic characters
                                  .toLowerCase()
                                  .indexOf(input.normalize('NFD').toLowerCase()) >= 0
                              }
                            >
                              {commissioners.map((client) => (
                                <Option
                                  key={client.nid}
                                  value={client._id}
                                >{`${client.nid} ${client.clientName}`}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row type='flex' gutter={16}>
                        <Col span={24}>
                          <Form.Item label='Datum sklapanja ugovora' name='contractDate'>
                            <DatePicker locale={locale} format='DD-MM-YYYY' />
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            label='Kategorija'
                            name='category'
                            rules={[
                              {
                                required: true,
                                message: 'Ovo polje je obavezno.',
                              },
                            ]}
                          >
                            <Radio.Group>
                              {category.map((option) => (
                                <Radio key={option.value} value={option.value}>
                                  {option.label}
                                </Radio>
                              ))}
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label='Vrsta'
                            name='type'
                            rules={[
                              {
                                required: true,
                                message: 'Ovo polje je obavezno.',
                              },
                            ]}
                          >
                            <Radio.Group>
                              {type.map((option) => (
                                <Radio key={option.value} value={option.value}>
                                  {option.label}
                                </Radio>
                              ))}
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row type='flex' gutter={16}>
                        <Col span={8}>
                          <Form.Item
                            label='Struktura'
                            name='structure'
                            rules={[
                              {
                                required: true,
                                message: 'Ovo polje je obavezno.',
                              },
                            ]}
                          >
                            <Select
                              virtual={false}
                              allowClear='true'
                              showSearch
                              placeholder='Pretražite strukture...'
                              optionFilterProp='children'
                              filterOption={(input, option) =>
                                option.props.children
                                  .normalize('NFD') // Normalize diacritic characters
                                  .toLowerCase()
                                  .indexOf(input.normalize('NFD').toLowerCase()) >= 0
                              }
                              onChange={(value) => setRequired(value)}
                            >
                              {structures.map((structure) => (
                                <Option key={structure._id} value={structure._id}>
                                  {structure.structureName}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row type='flex' gutter={16}>
                        <Col span={8}>
                          <Form.Item label='Adresa' name='address'>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label='Grad'
                            name='city'
                            rules={[
                              {
                                required: true,
                                message: 'Ovo polje je obavezno.',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label='Lokacija'
                            name='location'
                            rules={[
                              {
                                required: true,
                                message: 'Ovo polje je obavezno.',
                              },
                            ]}
                          >
                            <Select
                              virtual={false}
                              allowClear='true'
                              showSearch
                              placeholder='Pretražite lokacije...'
                              optionFilterProp='children'
                              filterOption={(input, option) =>
                                option.props.children
                                  .normalize('NFD') // Normalize diacritic characters
                                  .toLowerCase()
                                  .indexOf(input.normalize('NFD').toLowerCase()) >= 0
                              }
                            >
                              {locations.map((location) => (
                                <Option key={location._id} value={location._id}>
                                  {location.locationName}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label='Kvadratura'
                            style={{ width: 200 }}
                            name='squareMeter'
                            rules={[
                              {
                                required: true,
                                message: 'Ovo polje je obavezno.',
                              },
                            ]}
                          >
                            <Input type='number' suffix='m²' />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label='Sobe'
                            style={{ display: 'flex', gap: '10px', alignItems: 'flex-start' }}
                            name='rooms'
                            rules={[
                              {
                                required: true,
                                message: 'Ovo polje je obavezno.',
                              },
                            ]}
                          >
                            <Radio.Group>{rooms}</Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label='Grejanje'
                            name='heating'
                            rules={[
                              {
                                required: true,
                                message: 'Ovo polje je obavezno.',
                              },
                            ]}
                          >
                            <Radio.Group>
                              {heating.map((option) => (
                                <Radio key={option.value} value={option.value}>
                                  {option.label}
                                </Radio>
                              ))}
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label='Cena'
                            style={{ width: 200 }}
                            name='price'
                            rules={[
                              {
                                required: true,
                                message: 'Ovo polje je obavezno.',
                              },
                            ]}
                          >
                            <Input type='number' suffix='€' />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label='Fakturisan iznos' style={{ width: 200 }} name='invoicedAmount'>
                            <Input type='number' suffix='RSD' />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label='Površina placa'
                            style={{ width: 200 }}
                            name='landSurface'
                            rules={[
                              {
                                required: isRequired ? true : false,
                                message: 'Ovo polje je obavezno.',
                              },
                            ]}
                          >
                            <Input type='number' suffix='m²' />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label='List nepokretnosti' name='zkNumber'>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label='Broj parcele' name='plotNumber'>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label='Katastarska opština' name='cadastralTownship'>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item label='Opis sa internim beleškama' name='privDesc'>
                            <JoditEditor
                              name='privDesc'
                              style={{ margin: '2px 0px', height: '550px !important' }}
                              config={configJodit}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={16}>
                          <Form.Item label='Google Opis' name={['googleDescription', lang.code]}>
                            <TextArea rows={2} maxLength={160} />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item label='Opis za web stranicu' name={['webDesc', lang.code]}>
                            <JoditEditor
                              name={`webDesc[${lang.code}]`}
                              style={{ margin: '2px 0px', height: '550px !important' }}
                              config={configJodit}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label='Aktuelno od' name='currentDate'>
                            <DatePicker locale={locale} format='DD-MM-YYYY' />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row type='flex' gutter={16}>
                        <Col span={8}>
                          <Form.Item label='Nije aktuelno od' name='notCurrentDate'>
                            <DatePicker locale={locale} format='DD-MM-YYYY' />
                          </Form.Item>
                        </Col>
                        {!isNew && (data.category === 'prodaja/zamena' || data.category === 'prodaja') ? (
                          <Col span={8}>
                            <Form.Item label='Prodato' name='sold' style={{ width: 150 }}>
                              <Select virtual={false}>
                                {[
                                  { label: 'Da', value: true },
                                  { label: 'Ne', value: false },
                                ].map((option, index) => (
                                  <Select.Option key={`${option.label}_${index}`} value={option.value}>
                                    {option.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        ) : null}

                        {!isNew && data.category === 'izdavanje' ? (
                          <Col span={8}>
                            <Form.Item label='Izdato' name='rented' style={{ width: 150 }}>
                              <Select virtual={false}>
                                {[
                                  { label: 'Da', value: true },
                                  { label: 'Ne', value: false },
                                ].map((option, index) => (
                                  <Select.Option key={`${option.label}_${index}`} value={option.value}>
                                    {option.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        ) : null}
                        {!isNew ? (
                          <Col span={8}>
                            <Form.Item label='Klijent odustao' name='clientGaveUp' style={{ width: 150 }}>
                              <Select virtual={false}>
                                {[
                                  { label: 'Da', value: true },
                                  { label: 'Ne', value: false },
                                ].map((option, index) => (
                                  <Select.Option key={`${option.label}_${index}`} value={option.value}>
                                    {option.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        ) : null}
                      </Row>
                      <Row type='flex' gutter={16}>
                        {!isNew ? (
                          <Col span={8}>
                            <Form.Item label='Klijent prodao' name='ownerSold' style={{ width: 150 }}>
                              <Select virtual={false}>
                                {[
                                  { label: 'Da', value: true },
                                  { label: 'Ne', value: false },
                                ].map((option, index) => (
                                  <Select.Option key={`${option.label}_${index}`} value={option.value}>
                                    {option.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        ) : null}
                        <Col span={8}>
                          <Form.Item label='Objavljeno na sajtu' name='published' style={{ width: 150 }}>
                            <Select virtual={false}>
                              {[
                                { label: 'Da', value: true },
                                { label: 'Ne', value: false },
                              ].map((option, index) => (
                                <Select.Option key={`${option.label}_${index}`} value={option.value}>
                                  {option.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        {!isNew ? (
                          <Col span={8}>
                            <Form.Item label='Objavljeno na 4zida' name='postedCetiriZida' style={{ width: 150 }}>
                              <Select virtual={false}>
                                {[
                                  { label: 'Da', value: true },
                                  { label: 'Ne', value: false },
                                ].map((option, index) => (
                                  <Select.Option key={`${option.label}_${index}`} value={option.value}>
                                    {option.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        ) : null}
                      </Row>
                      <Row type='flex' gutter={16}>
                        <Col span={24}>
                          <Form.Item label='Objavljeno na' name='postedAt'>
                            <Checkbox.Group
                              // style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}
                              value={checkedList}
                              onChange={onChange}
                            >
                              {['Svaštara', 'Hallo oglasi', 'Sasomange', 'Četiri Zida'].map((option) => (
                                <Checkbox key={option} value={option}>
                                  {option}
                                </Checkbox>
                              ))}
                            </Checkbox.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Tabs.TabPane>
                  ))}
                </Tabs>

                {/* <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={8}>
                    <Form.Item label='Featured' name='featuredProduct' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                  </Col>
                </Row> */}

                {/* <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={8}>
                    <Form.Item label='Featured' name='featuredAd' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item label='Featured until' name='featuredAdUntil' getValueProps={() => {}} rules={[{ type: 'object' }]}>
                      <DatePicker style={{ width: '100%' }} size='large' rules={[{ type: 'object' }]} />
                    </Form.Item>
                  </Col>
                </Row> */}
              </div>
            </Col>

            <Col xs={24} md={9}>
              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Glavna slika</h4>
                </div>

                <Form.Item
                  name='featureImage'
                  valuePropName='image'
                  rules={[
                    {
                      required: true,
                      message: 'Ovo polje je obavezno.',
                    },
                  ]}
                >
                  <div
                    className='panel-body'
                    style={{ display: 'flex', gap: '30px', alignItems: 'center', margin: '20px 0' }}
                  >
                    <UploadBox
                      editHandler={editImageTrigger}
                      deleteHandler={deleteFeatureImageHandler}
                      image={primaryImage && primaryImage.url}
                      index={0}
                      name='featureImage'
                    />
                    {primaryImage && (
                      <>
                        <label htmlFor=''>Opis slike:</label>
                        <Input
                          type='text'
                          defaultValue={primaryImage !== undefined ? primaryImage.pictureDesc : ''}
                          onChange={(e) => handleImageDesc(e.target.value, primaryImage._id)}
                        />
                      </>
                    )}
                  </div>
                </Form.Item>
              </div>

              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Galerija na sajtu</h4>
                </div>

                <Form.Item
                  name='gallery'
                  valuePropName='image'
                  rules={[
                    {
                      required: true,
                      message: 'Ovo polje je obavezno.',
                    },
                  ]}
                >
                  <div className='panel-body'>
                    {currentGallery?.length > 0 && (
                      <DNDGallery
                        deleteGalleryImageHandler={deleteGalleryImageHandler}
                        editGallerySingleImageTrigger={editGallerySingleImageTrigger}
                        form={form}
                        setGallery={setCurrentGallery}
                        currentGallery={currentGallery}
                      />
                    )}

                    {currentGallery?.length < GALLERY_LIMIT && <UploadBox editHandler={editGalleryTrigger} />}
                  </div>
                </Form.Item>
              </div>

              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Interna Galerija</h4>
                </div>

                <Form.Item name='privGallery' valuePropName='image'>
                  <div className='panel-body'>
                    {currentPrivGallery?.length > 0 && (
                      <DNDPrivGallery
                        deletePrivGalleryImageHandler={deletePrivGalleryImageHandler}
                        editPrivGallerySingleImageTrigger={editPrivGallerySingleImageTrigger}
                        form={form}
                        setPrivGallery={setCurrentPrivGallery}
                        currentGallery={currentPrivGallery}
                      />
                    )}

                    {currentPrivGallery?.length < GALLERY_LIMIT && <UploadBox editHandler={editPrivGalleryTrigger} />}
                  </div>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <div className='text-right'>
              <Button type='primary' htmlType='submit'>
                {isNew ? 'Kreiraj' : 'Ispravi'} nekretninu
              </Button>
            </div>
          </div>
        </Form>

        {modal.visible && (
          <GalleryModal
            visible={modal.visible}
            limit={modal.limit}
            formKey={modal.formKey}
            imageSavePath='public/images/data/'
            imageType='data'
            imageHeight={500}
            SERVER_URL={SERVER_URL}
            token={token}
            form={form}
            onCancel={() => setModal({ visible: false, formKey: null, limit: 1 })}
            onInsert={(values) => onInsert(values, modal.formKey)}
            imageId={modal.id}
            imageIndex={modal.index}
          />
        )}
      </div>
    </div>
  );
};

export default DataFrom;
