import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { notification, Button, Input } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/ClientsTable';
import ExportExcel from '../../components/csv/ExportExcel';
import { SERVER_URL } from '../../config';
import EditClientModal from '../../components/modals/EditClientModal';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'createdAt', 'updatedAt', 'clientType', 'brLk', 'jmbg'];

const ClientsBuyers = () => {
  const currentuser = useContext(UserContext);
  const [users, fetchUsers] = useAxios('', [], currentuser.data.token, 'get');
  const [showModal, setShowModal] = useState();
  const [search, setSearch] = useState();
  const [useri, setUseri] = useState();
  const [csvUsers, setCsvUsers] = useState(undefined);

  useEffect(() => {
    const filter = {
      clientType: { $in: ['kupac'] },
    };
    fetchUsers(`${SERVER_URL}/clients?filter=` + encodeURIComponent(JSON.stringify(filter)), []);
  }, [fetchUsers]);

  useEffect(() => {
    let newUsersData = [];
    if (users.data.items) {
      const arrayToString = (arr) => {
        if (Array.isArray(arr)) {
          if (arr.length === 1) {
            return arr[0];
          } else {
            return arr.join(', ');
          }
        } else {
          return '';
        }
      };

      for (const user of users.data.items) {
        const arr = user.clientType;
        user.clientType = arrayToString(arr);
        newUsersData.push(user);
      }
    }

    setCsvUsers(newUsersData);
  }, [users]);

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = showModal ? 'hidden' : 'auto';
  }, [showModal]);

  //Moze da se pretrazuje po imenu, prezimenu, role, status, grad,email
  //Nalazi se odredjivanje toga u user.controllers.js u searchUsers

  useEffect(() => {
    if (search) {
      Axios.get(`${SERVER_URL}/clients/search/${search}`)
        .then((res) => {
          setUseri(res.data.users);
        })
        .catch((err) => console.log(err));
    } else {
      setUseri([]);
    }
  }, [search, currentuser]);

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/clients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Klijent je obrisan.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      console.log('Error:', err.response);
      if (err.response && err.response.status === 400) {
        notification.error({
          message: err.response.data.message,
          placement: 'bottomRight',
        });
      } else {
        notification.error({
          message: 'Problem pri brisanju. Molimo pokušajte kasnije.',
          placement: 'bottomRight',
        });
      }
    }
  };
  let columnKeys;
  if (users.data.items && users.data.items.length > 0) {
    const keys = Object.keys(users.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (search) {
    if (useri && useri.length > 0) {
      tableData = useri.map((item) => {
        if (Array.isArray(item.role)) item.role = item.role.join(', ');
        item.createdAt = new Date(item.createdAt).toLocaleString();
        item.updatedAt = new Date(item.updatedAt).toLocaleString();
        return item;
      });
    }
  } else if (users.data.items && users.data.items.length > 0) {
    tableData = users.data.items.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  return (
    <div className='table usersWrapper'>
      <div className='actions-block' style={{ marginBottom: '8px' }}>
        {/*<Link to='/admin/new-user'>*/}
        <Button type='primary'>
          <Link style={{ textDecoration: 'none' }} to='/admin/new-buyer'>
            Dodaj novog kupca
          </Link>
        </Button>
        <h2
          style={{
            marginBottom: '0',
            fontWeight: 'bold',
            textAlign: 'center',
            color: 'grey',
            fontSize: '30px',
          }}
        >
          Kupci
        </h2>
        <div className='desna-strana'>
          <ExportExcel
            csvData={csvUsers != null && csvUsers.length >= 1 ? csvUsers : []}
            fileName={`Export kupaca ` + new Date().toLocaleString()} // Ime xlsx fajla
            propsToRemove={['_id', 'updatedAt', '__v', 'createdAt']} // Opcija 1 - uklanjaju se polja koje ne treba da se exportuju
            // format="products" // Opcija 2 - custom formatiranje - treba po imenu format polja u exportExcel.js formatirati polja po zelji
          />

          {/*</Link>*/}
        </div>
      </div>

      <div style={{ textAlign: 'center' }}>
        {users.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!users.isLoading && users.data.items && users.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteUserHandler}
            columnKeys={columnKeys}
            title='User'
            editPath='/admin/edit-client/'
            viewPath='/admin/view-buyer/'
          />
        )}
        {!users.isLoading && users.data.items && users.data.items.length === 0 && (
          <div className='no-data-box'>
            <h2>Nema podataka</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientsBuyers;
