import React, { useEffect, useContext, useState } from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { Avatar, Button, notification, Layout, Tabs, Typography, Card, Row, Col, Collapse } from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserLog } from '../../components/forms';
import dayjs from 'dayjs';
import DemandForm from '../../components/forms/DemandForm';
import Table from '../../components/tables/DataInDemandViewTable';
const { Panel } = Collapse;
const { Content, Sider } = Layout;
const { Text } = Typography;
const { TabPane } = Tabs;

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'heating',
  'webDesc',
  'privDesc',
  'featureImage',
  'flag',
  'views',
  'currentDate',
  'notCurrentDate',
  'gallery',
  'privGallery',
  'comments',
  'user',
  'createdAt',
  'updatedAt',
  'sold',
  'structure',
  'isExpiredContract',
  'isMailSent',
  'client',
  'location',
  'contractDate',
  'published',
  'zkNumber',
  'plotNumber',
  'landSurface',
  'eb',
  'createdByUser',
  'soldByUser',
  'rented',
  'postedCetiriZida',
  'clientGaveUp',
  'googleDescription',
  'createdByUserDate',
  'soldByUserDate',
  'postedAt',
  'coOwners',
  'ownerSold',
  'url',
  'cadastralTownship',
  'commissioner',
  'invoicedAmount',
];

const ViewDemand = (props) => {
  const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [clients, fetchClients] = useAxios('', {}, currentuser.data.token, 'get');
  const [locations, fetchLocations] = useAxios('', {}, currentuser.data.token, 'get');
  const [structures, fetchStructures] = useAxios('', {}, currentuser.data.token, 'get');
  const [demand, fetchDemand] = useAxios('', [], currentuser.data.token, 'get');
  let clientsData = clients.data;
  let locationsData = locations.data;
  let structuresData = structures.data;
  const history = useHistory();

  useEffect(() => {
    if (id) fetchDemand(`${SERVER_URL}/demand-view/${id}`, {});
    if (demand && demand.data) {
      const filter = {
        squareMeter: {
          $gte: demand?.data?.squareMeterFrom,
          $lte: demand?.data?.squareMeterTo,
        },
        price: {
          $gte: demand?.data?.priceFrom,
          $lte: demand?.data?.priceTo,
        },
        type: demand?.data?.type,
        category: demand?.data?.category,
        location: demand?.data?.location?._id,
        $and: [
          { sold: { $ne: true } },
          { rented: { $ne: true } },
          { clientGaveUp: { $ne: true } },
          { ownerSold: { $ne: true } },
        ],
      };

      if (demand.data.squareMeterFrom || demand.data.sqareMeterTo || demand.data.priceFrom || demand.data.priceTo) {
        fetchData(`${SERVER_URL}/data?filter=` + encodeURIComponent(JSON.stringify(filter)), []);
      }
    }

    fetchClients(`${SERVER_URL}/clients`, []);
    fetchLocations(`${SERVER_URL}/locations`, []);
    fetchStructures(`${SERVER_URL}/structures`, []);
  }, [id, clientsData, demand, locationsData, structuresData, SERVER_URL]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/data/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Nekretnina je obrisana.',
        placement: 'bottomRight',
      });
      window.location.reload();
    } catch (err) {
      if (err.response && err.response.status === 400) {
        notification.error({
          message: err.response.data.message,
          placement: 'bottomRight',
        });
      } else {
        notification.error({
          message: 'Problem pri brisanju. Molimo pokušajte ponovo.',
          placement: 'bottomRight',
        });
      }
    }
  };

  let columnKeys;
  let updatedColumnKeys;
  const desiredKeysClient = ['clientName'];
  const desiredKeysLocation = ['locationName'];
  if (data.data && data.data.items && data.data.items.length > 0) {
    let firstItem = data.data.items[0];
    for (const item of data.data.items) {
      console.log(item);
      if (item.client !== undefined && item.client !== null && item.location !== undefined && item.location !== null) {
        // Found the desired item, create an object with all properties
        firstItem = { ...item };
        break; // Exit the loop after finding the first matching item
      }
    }

    const keys = Object.keys(firstItem);

    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    updatedColumnKeys = columnKeys;

    if (firstItem.client !== undefined && firstItem.client !== null) {
      const clientKeys = Object.keys(firstItem.client).filter((key) => desiredKeysClient.includes(key));

      updatedColumnKeys = [...new Set(updatedColumnKeys.concat(clientKeys))];
    }

    if (firstItem.location !== undefined && firstItem.location !== null) {
      const locationKeys = Object.keys(firstItem.location).filter((key) => desiredKeysLocation.includes(key));

      updatedColumnKeys = [...new Set(updatedColumnKeys.concat(locationKeys))];
    }
  }

  let tableData = [];

  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();

      const regex = /^(\d{4})-(\d{2})-(\d{2}).*/;
      const match = item.contractDate !== null && item.contractDate.match(regex);

      if (match) {
        const [, year, month, day] = match;
        const date = `${day}.${month}.${year}.`;
        item.contractDate = date;
      }

      item.contractDate = item.contractDate || 'Nema podatka';

      item.locationName = item.location?.locationName || 'Nema podatka';
      item.clientName = item.client?.clientName || 'Nema podatka';

      item.realEstateId = item.realEstateId;

      if (item.published === true) item.published = 'Da';
      if (item.published === false) item.published = 'Ne';
      return item;
    });
  }

  const getUrl = (itemUrl) => {
    let url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;

    return url;
  };

  // useEffect(() => {
  //   if (id) {
  //     let sevenDayBefor = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
  //     let sevenDayBeforGet = dayjs(sevenDayBefor).format('YYYY-MM-DD');
  //     let from = sevenDayBeforGet;
  //     let to = new Date();
  //     let fromTo = from + '||' + to;
  //     fetchCSV(`${SERVER_URL}/logsCSV/${id}/${JSON.stringify(fromTo)}`, []);
  //   }
  // }, [id, fetchCSV]);

  // const updateUser = async (data) => {
  //   if (data) {
  //     let fromTo = data.from + '||' + data.to;
  //     fetchCSV(`${SERVER_URL}/logsCSV/${id}/${JSON.stringify(fromTo)}`, []);
  //   }
  // };

  // const handleSignOutSubmit = async (event) => {
  //   event.preventDefault();
  //   sessionStorage.removeItem('user');
  //   window.location.href = '/login';
  // };
  const isDataFetched = !data.isLoading && data.data && currentuser.language;
  const isDemandFetched = !demand.isLoading && demand.data && currentuser.language;
  const isClientsFetched = !clients.isLoading && clientsData.items;
  const isLocationsFetched = !locations.isLoading && locationsData.items;
  const isStructuresFetched = !structures.isLoading && structuresData.items;

  const onSubmit = async (formData, isNew) => {
    const method = 'put';
    const route = `${SERVER_URL}/demand-view/${id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Potražnja ${isNew ? 'je kreirana.' : 'je ažurirana.'}`,
        placement: 'bottomRight',
      });

      if (isNew) {
        history.push('/admin/demand');
        window.location.reload();
      }
      if (!isNew) {
        history.push(`/admin/demand-view/${id}`);
        window.location.reload();
      }
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;

      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Link to='/admin/demand'>
          <Button type='primary'>Nazad na potražnje</Button>
        </Link>
      </div>
      <div className='content content-full-height'>
        {id && demand.isLoading && <LoadingOutlined className='loader' spin />}
        {id && !demand.isLoading && demand.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {id && demand && !demand.isLoading && !demand.isError && (
          <Layout className='site-layout-background'>
            <Content style={{ padding: '0 15px', minHeight: 280, background: '#fff' }}>
              <Tabs defaultActiveKey='1' /* onChange={callback} */>
                <TabPane tab='PREGLED' key='1'>
                  <div className='card-wrapper'>
                    <Card title='Podaci o potražnji' bordered={false}>
                      <div className='data-view-grid'>
                        <div>
                          <p style={{ textTransform: 'capitalize' }}>
                            <Text strong>Klijent: </Text>
                            {demand.data.client ? (
                              <Link to={`/admin/view-client/${demand?.data?.client?._id}`}>
                                {demand?.data?.client?.clientName}
                              </Link>
                            ) : (
                              <Text strong>Nema podatka</Text>
                            )}
                          </p>
                          <p>
                            <Text strong>Adresa: </Text>
                            {demand.data.client ? demand.data.client.address : <Text strong>Nema podatka</Text>}
                          </p>
                          <p>
                            <Text strong>Grad: </Text>
                            {demand.data.client ? demand.data.client.city : <Text strong>Nema podatka</Text>}
                          </p>
                          <p>
                            <Text strong>Mobilni telefon: </Text>
                            {demand.data.client ? demand.data.client.phone : <Text strong>Nema podatka</Text>}
                          </p>
                          <p>
                            <Text strong>Potražnja kreirana od: </Text>
                            {demand.data.createdByUser ? (
                              `${demand.data.createdByUser.firstName} ${demand.data.createdByUser.lastName}`
                            ) : (
                              <Text strong>Nema podatka</Text>
                            )}
                          </p>
                          <div className='demand-wrapper'>
                            <label>Potražnja</label>
                            <p style={{ textTransform: 'capitalize' }}>
                              <Text strong>Vrsta: </Text>
                              {demand.data.type ? demand.data.type : <Text strong>Nema podatka</Text>}
                            </p>
                            <p style={{ textTransform: 'capitalize' }}>
                              <Text strong>Kategorija: </Text>
                              {demand.data.category ? demand.data.category : <Text strong>Nema podatka</Text>}
                            </p>
                            <p>
                              <Text strong>Lokacija: </Text>
                              {demand.data.location ? (
                                demand.data.location.locationName
                              ) : (
                                <Text strong>Nema podatka</Text>
                              )}
                            </p>
                            <p>
                              <Text strong>Sobe: </Text>
                              {demand.data.rooms ? demand.data.rooms : <Text strong>Nema podatka</Text>}
                            </p>
                            <p>
                              <Text strong>Kvadratura od: </Text>
                              {demand.data.squareMeterFrom ? (
                                demand.data.squareMeterFrom + ' m²'
                              ) : (
                                <Text strong>Nema podatka</Text>
                              )}
                            </p>
                            <p>
                              <Text strong>Kvadratura do: </Text>
                              {demand.data.squareMeterTo ? (
                                demand.data.squareMeterTo + ' m²'
                              ) : (
                                <Text strong>Nema podatka</Text>
                              )}
                            </p>
                            <p>
                              <Text strong>Cena od: </Text>
                              {demand.data.priceFrom ? demand.data.priceFrom + ' €' : <Text strong>Nema podatka</Text>}
                            </p>
                            <p>
                              <Text strong>Cena do: </Text>
                              {demand.data.priceTo ? demand.data.priceTo + ' €' : <Text strong>Nema podatka</Text>}
                            </p>
                          </div>

                          <Text strong>Beleške: </Text>
                          <Collapse style={{ maxWidth: '500px', marginBottom: '20px' }}>
                            <Panel header='Prikaži beleške' key='1'>
                              {demand.data.note ? <p>{demand.data.note}</p> : <Text strong>Nema podatka</Text>}
                            </Panel>
                          </Collapse>
                        </div>
                      </div>
                    </Card>
                    <Text strong>Nekretnine: </Text>
                    <div style={{ textAlign: 'center' }}>
                      {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
                      {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
                        <Table
                          data={tableData}
                          deleteHandler={deleteDataHandler}
                          columnKeys={updatedColumnKeys}
                          title='Data'
                          editPath='/admin/edit-data/'
                          viewPath='/admin/view-data/'
                        />
                      )}
                      {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && (
                        <div className='no-data-box'>
                          <h2>Nema podataka</h2>
                        </div>
                      )}
                    </div>
                  </div>
                </TabPane>
                <TabPane tab='IZMENA' key='2'>
                  {id &&
                    !demand.isError &&
                    demand.data &&
                    clientsData &&
                    isDemandFetched &&
                    isClientsFetched &&
                    isLocationsFetched &&
                    isStructuresFetched && (
                      <DemandForm
                        isNew={false}
                        data={demand.data}
                        clients={clientsData.items}
                        locations={locationsData.items}
                        structures={structuresData.items}
                        language={currentuser.language}
                        onSubmit={onSubmit}
                        SERVER_URL={SERVER_URL}
                        token={currentuser.data.token}
                      />
                    )}
                </TabPane>
              </Tabs>
            </Content>
          </Layout>
        )}
      </div>
      {/* <EditUserModal userId={id} showModal={showModal} setShowModal={setShowModal} profile={true} /> */}
    </div>
  );
};

export default ViewDemand;
