import React, { useContext } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { DeleteFilled, DeleteOutlined, EditFilled, EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';

const DataTable = ({ data, deleteHandler, columnKeys, columnKeysTranslated, title, editPath, viewPath }) => {
  const user = useContext(UserContext);
  let searchInput;
  const columnKeyTranslator = (key) => {
    let translatedKey;
    let width;

    switch (key) {
      case 'price':
        translatedKey = 'cena';
        width = '100px';
        break;
      case 'rooms':
        translatedKey = 'sobe';
        width = '70px';
        break;
      case 'landSurface':
        translatedKey = 'površina placa';
        width = '125px';
        break;
      case 'squareMeter':
        translatedKey = 'kvadratura';
        width = '110px';
        break;
      case 'contractDate':
        translatedKey = 'datum sklapanja ugovora';
        width = '160px';
        break;
      case 'eb':
        translatedKey = 'EB';
        width = '80px';
        break;
      case 'clientName':
        translatedKey = 'klijent';
        width = '150px';
        break;
      case 'category':
        translatedKey = 'kategorija';
        width = '115px';
        break;
      case 'type':
        translatedKey = 'vrsta';
        width = '120px';
        break;
      case 'address':
        translatedKey = 'adresa';
        width = '130px';
        break;
      case 'city':
        translatedKey = 'grad';
        width = '100px';
        break;
      case 'zkNumber':
        translatedKey = 'List nepokretnosti';
        width = '95px';
        break;
      case 'plotNumber':
        translatedKey = 'broj parcele';
        width = '110px';
        break;
      case 'published':
        translatedKey = 'objavljeno';
        width = '100px';
        break;
      case 'locationName':
        translatedKey = 'lokacija';
        width = '130px';
        break;
      case 'realEstateId':
        translatedKey = 'id';
        width = '60px';
        break;
      default:
        console.log('Error', 'Column key not found in array');
    }
    return { title: translatedKey, width: width };
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Pretraži`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Pretraži
          </Button>
          <Button type='secondary' onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Vrati
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? !dataIndex.includes('category')
          ? record[dataIndex[0]][dataIndex[1]]
          : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const sortOrder = [
    'nid',
    'eb',
    'type',
    'category',
    'squareMeter',
    'rooms',
    'price',
    'address',
    'city',
    'locationName',
    'landSurface',
    'plotNumber',
    'zkNumber',
    'name',
    'contractDate',
    'published',
  ];

  const customSort = (a, b) => {
    const indexA = sortOrder.indexOf(a);
    const indexB = sortOrder.indexOf(b);
    return indexA - indexB;
  };
  columnKeys.sort(customSort);
  const columns = columnKeys.map((item) => {
    return {
      key: item,
      title: columnKeyTranslator(item).title,
      dataIndex: item,
      render:
        columnKeyTranslator(item).title === 'cena'
          ? (item) => `${item.toLocaleString()} €`
          : columnKeyTranslator(item).title === 'kvadratura' || columnKeyTranslator(item).title === 'površina placa'
          ? (item) => (item ? `${item.toLocaleString()} m²` : 'Nema podatka')
          : (item) => `${item}`,
      ...getColumnSearchProps(
        item === 'createdBy'
          ? 'user'
          : ['title', 'category'].includes(item)
          ? item === 'title'
            ? [item, user.language ? user.language.selected.code : '']
            : [item, 'name', user.language ? user.language.selected.code : '']
          : item,
      ),
      ellipsis: true,
      // Optional: Enable text ellipsis if the content exceeds cell width
    };
  });

  columns.push({
    title: 'Akcije',
    //width: '10vw' /*bilo je bez width,ali se tada ne poravnaju linije header i body*/,
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        {/* <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <Link to={editPath + record._id}>
            <EditOutlined
              className='icon-unlock'
              title={`Ispravi nekretninu`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
            <EditFilled
              className='icon-lock'
              title={`Ispravi nekretninu`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </Link>
        </div> */}

        {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Link to={viewPath + record._id}>
              <EyeOutlined
                className='icon-unlock'
                title={`Pregled nekretnine`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
              <EyeOutlined
                className='icon-lock'
                title={`Pregled nekretnine`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>
        )}

        {/* <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            title={`Da li želite da obrišete nekretninu?`}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText='Da'
            cancelText='Ne'
            cancelButtonProps={{ type: 'secondary' }}
            okButtonProps={{ type: 'primary' }}
          >
            <DeleteOutlined className='icon-unlock' title={`Obriši nekretninu}`} />
            <DeleteFilled className='icon-lock' title={`Obriši nekretninu`} />
          </Popconfirm>
        </div> */}
      </div>
    ),
  });

  return (
    <div className='table-wrapper'>
      {' '}
      {/* style={{ height: '85vh' }} */}
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 50,
          position: ['topCenter','bottomCenter'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '150'],
          hideOnSinglePage: true,
        }}
        scroll={{ y: '88vh' }} // 74vh
        fixed
      />
    </div>
  );
};

export default DataTable;
