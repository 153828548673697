import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button, DatePicker } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/EopTable';
import { SERVER_URL } from '../../config';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'anon',
  'birthDate',
  'expireDate',
  'birthPlace',
  'months',
  'origin',
  'otherClientData',
  'realEstate',
  'addressClients',
  'typeClient',
  'jmbgPib',
  'blkMb',
  'clientOcupation',
  'residency',
  'domicile',
  'township',
  'riskyClient',
  'documentation',
  'riskType',
  'moneyLaunder',
  'realOwnerData',
  'coOwners',
  'createdAt',
  'updatedAt',
  'cut',
  'businessShip',
  'typeClientOcupation',
  'createdByUser',
];

const Eop = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [filter, setFilter] = useState({});

  const [year, setYear] = useState();
  const history = useHistory();
  const [eopCount, setEopCount] = useState(0);

  useEffect(() => {
    fetchData(`${SERVER_URL}/eop?filter=` + encodeURIComponent(JSON.stringify(filter)), []);
    if (data && data.data && data.data.items) setEopCount(data.data.items.length);
  }, [data, filter, year]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/eop/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'EOP je obrisan.',
        placement: 'bottomRight',
      });
      window.location.reload();
    } catch (err) {
      notification.error({
        message: 'Problem pri brisanju. Molimo pokušajte ponovo.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let updatedColumnKeys;
  const desiredKeysRealEstate = ['city', 'address', 'type', 'squareMeter', 'notCurrentDate', 'price'];

  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));

    updatedColumnKeys = columnKeys;
    let realEstateKeys;

    if (data.data.items[0].realEstate !== null) {
      realEstateKeys = Object.keys(data.data.items[0].realEstate).filter((key) => desiredKeysRealEstate.includes(key));
      updatedColumnKeys = columnKeys.concat(realEstateKeys.filter((key) => !columnKeys.includes(key)));
    }
  }

  let tableData = [];

  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item, key) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      item.eopId = data.data.items.length - key;

      if (item && item.client) {
        item.client =
          item.realEstate !== null
            ? `${item.realEstate.client?.clientName}, ${item.realEstate.client?.address}`
            : 'Nema podatka';
      } else {
        const client =
          item.realEstate !== null
            ? `${item.realEstate?.client?.clientName}, ${item.realEstate?.client?.address}`
            : 'Nema podatka';
        item = {
          ...item,
          client,
        };
      }

      const regex = /^(\d{4})-(\d{2})-(\d{2}).*/;
      if (item.contractDate) {
        const matchCD = item.contractDate && item.contractDate.match(regex);

        if (matchCD) {
          const year = matchCD[1];
          const month = matchCD[2];
          const day = matchCD[3];

          const date = `${day}.${month}.${year}.`;
          item.contractDate = date;
        }
      }

      const matchED = item.entryDate.match(regex);
      if (matchED) {
        const year = matchED[1];
        const month = matchED[2];
        const day = matchED[3];
        const date = `${day}.${month}.${year}.`;
        item.entryDate = date;
      }

      item.city = item.realEstate !== null ? item.realEstate.city : 'Nema podatka';
      // item.price = item.realEstate !== null ? item.realEstate.price : 'Nema podatka';
      item.address =
        item.realEstate !== null
          ? `${item.realEstate.address}${item.realEstate.plotNumber ? `, ${item.realEstate.plotNumber}` : ''}${
              item.realEstate.cadastralTownship ? `, ${item.realEstate.cadastralTownship}` : ''
            }`
          : 'Nema podatka';
      item.type = item.realEstate !== null ? item.realEstate.type : 'Nema podatka';
      item.squareMeter =
        item.realEstate !== null
          ? item.realEstate.type !== 'plac'
            ? `${item.realEstate.squareMeter ? `${item.realEstate.squareMeter} m²` : ''} `
            : `${
                item.realEstate.squareMeter
                  ? `${item.realEstate.squareMeter} m²`
                  : item.realEstate.landSurface
                  ? `${item.realEstate.landSurface} m²`
                  : ''
              }
            `
          : 'Nema podatka';

      const matchNCD = item.realEstate.notCurrentDate !== null && item.realEstate.notCurrentDate.match(regex);
      if (
        matchNCD &&
        !item.realEstate.ownerSold &&
        !item.realEstate.clientGaveUp &&
        item.realEstate.notCurrentDate !== null
      ) {
        const year = matchNCD[1];
        const month = matchNCD[2];
        const day = matchNCD[3];
        const date = `${day}.${month}.${year}.`;
        item.notCurrentDate = date;
      } else {
        item.notCurrentDate = 'Nema podatka';
      }

      // item.notCurrentDate = 'Nema podatka';
      if (item.published === true) item.published = 'Da';
      if (item.published === false) item.published = 'Ne';
      return item;
    });
  }
  const onChange = (date) => {
    const dateString = date.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    const yearRegex = /(\d{4})-/; // Matches the first four digits followed by a hyphen
    const year = yearRegex.exec(dateString)[1];
    setYear(year);
    setFilter({
      entryDate: {
        $regex: year,
      },
    });
  };
  const formatDate = (date) => {
    const regex = /^(\d{4})-(\d{2})-(\d{2}).*/;
    const match = date !== null && date.match(regex);

    if (match) {
      const [, year, month, day] = match;
      const date = `${day}.${month}.${year}.`;
      return date;
    } else {
      return '';
    }
  };
  const handleDownload = (data, year) => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('EOP');
      worksheet.views = [{ showGridLines: false }];

      const cellB1 = worksheet.getCell('B1');
      cellB1.value = 'Posrednik:';
      const cellB2 = worksheet.getCell('B2');
      cellB2.value = 'Adresa:';
      const cellB3 = worksheet.getCell('B3');
      cellB3.value = 'PIB:';
      const cellB4 = worksheet.getCell('B4');
      cellB4.value = 'Matični broj:';

      cellB1.alignment = { horizontal: 'right' };
      cellB2.alignment = { horizontal: 'right' };
      cellB3.alignment = { horizontal: 'right' };
      cellB4.alignment = { horizontal: 'right' };

      const cellC1 = worksheet.getCell('C1');
      cellC1.value = 'CENZAR PREDUZEĆE ZA PROIZVODNJU, INŽINJERING I PROMET ROBA, USLUGA I NEKRETNINA DOO SUBOTICA';

      const cellC2 = worksheet.getCell('C2');
      cellC2.value = 'Prvomajska 22, Subotica';

      const cellC3 = worksheet.getCell('C3');
      cellC3.value = '100844526';

      const cellC4 = worksheet.getCell('C4');
      cellC4.value = '08281556';

      worksheet.getCell('I1').value = 'Upisan u Registar posrednika pod brojem:';
      // worksheet.getCell('N1').value = `${data.data.items.length}`;
      // Merge cells I1 to M1
      worksheet.mergeCells('I1:M1');

      // Set alignment to right for the merged cell
      const mergedCellI1M1 = worksheet.getCell('I1');
      mergedCellI1M1.alignment = { horizontal: 'right' };

      // Merge cells M3 to N3
      worksheet.mergeCells('M3:N3');

      // Set bottom border for the merged cell
      const mergedCellM3N3 = worksheet.getCell('M3');
      mergedCellM3N3.border = {
        bottom: { style: 'thick' },
      };

      worksheet.getCell('M4').value = 'Potpis odgovornog lica';
      worksheet.getCell('M4').alignment = { horizontal: 'center' };
      // Merge cells M3 to N3
      worksheet.mergeCells('M4:N4');

      worksheet.getCell('A5').value = 'EVIDENCIJA O POSREDOVANJU U PROMETU I ZAKUPU NEPOKRETNOSTI';
      worksheet.getCell('A5').alignment = { horizontal: 'center' };
      worksheet.mergeCells('A5:N5');

      worksheet.getCell('A6').value = `za ${year}. godinu`;
      worksheet.getCell('A6').alignment = { horizontal: 'center' };
      worksheet.mergeCells('A6:N6');

      let dataForTable = data.data.items
        .sort((a, b) => {
          const parseDate = (dateString) => {
            const [day, month, year] = dateString.split('.').map(Number);
            return new Date(year, month - 1, day); // Month is zero-based in JavaScript dates
          };

          const dateA = parseDate(a.entryDate);
          const dateB = parseDate(b.entryDate);

          return dateA.getTime() - dateB.getTime();
        })
        .flatMap((data, key) => {
          // Split the clients by comma and create a new array for each client
          const clients = [
            `${data.realEstate.client.clientName}, ${data.realEstate.address ? data.realEstate.client.address : ''}, ${
              data.realEstate.city ? data.realEstate.client.city : ''
            }`,
          ];

          // Check if there are any co-owners
          if (data.realEstate.coOwners && data.realEstate.coOwners.length > 0) {
            // Map over the co-owners and add their names to the clients array
            data.realEstate.coOwners.forEach((coOwner) => {
              clients.push(
                `${coOwner.clientName}, ${coOwner.address ? coOwner.address : ''}, ${coOwner.city ? coOwner.city : ''}`,
              );
            });
          }

          let cut;
          if (data.realEstate.client !== null && data.realEstate.coOwners && data.realEstate.coOwners.length > 0) {
            cut = [data.realEstate.client, ...data.realEstate.coOwners].length;
          }
          // Map over the clients and create a new array for each client
          return clients.map((client, index) => [
            `${data.entryDate}`, // Show the entryDate only for the first client
            `${data.contractNum}`, // Show the contractNum only for the first client
            `${formatDate(data.realEstate.contractDate)}`, // Show the contractDate only for the first client
            `${client}`, // Show the clientName and address for each client (including co-owners)
            `${data.realEstate.city}`, // Show the city only for the first client
            `${data.realEstate.address}, ${data.realEstate.plotNumber !== null ? data.realEstate.plotNumber : ''}, ${
              data.realEstate.cadastralTownship ? data.realEstate.cadastralTownship : ''
            }`, // Show the address only for the first client
            `${data.realEstate.type}`, // Show the type only for the first client
            `${data.realEstate.squareMeter && data.realEstate.squareMeter.toLocaleString() + ' m²'}\n`,
            // `${data.realEstate.landSurface ? (data.realEstate.landSurface / 100).toLocaleString() + ' ari' : ''}`, // Show the squareMeter and landSurface only for the first client
            `${
              data.realEstate.notCurrentDate !== null && !data.realEstate.ownerSold && !data.realEstate.clientGaveUp
                ? 'zaključen'
                : 'nije zaključen'
            }`, // Show the status only for the first client
            `${
              data.realEstate.notCurrentDate !== null && !data.realEstate.ownerSold && !data.realEstate.clientGaveUp
                ? formatDate(data.realEstate.notCurrentDate)
                : ''
            }`,
            `${data.realEstate.price && data.realEstate.price.toLocaleString() + ' €'}`,
            `${data.realEstate.invoicedAmount ? data.realEstate.invoicedAmount.toLocaleString() + ' RSD' : ''}`,
            `${
              data.realEstate.client &&
              (data.realEstate.coOwners === undefined || data.realEstate.coOwners.length === 0)
                ? `[VL] ${
                    data.realEstate.commissioner && client.split(',')[0] === data.realEstate.commissioner.clientName
                      ? 'Punomoćnik'
                      : ''
                  }`
                : `[suVL 1/${cut}] ${
                    data.realEstate.commissioner && client.split(',')[0] === data.realEstate.commissioner.clientName
                      ? 'Punomoćnik'
                      : ''
                  }`
            }`, // Show the note only for the first client
          ]);
        });
      dataForTable = dataForTable.map((array, index) => [index + 1, ...array]);

      // Define the range for the table
      const startCell = 'A8';
      const endCell = `N${dataForTable.length + 9}`;
      const range = worksheet.getCell(startCell).address + ':' + worksheet.getCell(endCell).address;

      // Define the table data
      const tableData = [['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14'], ...dataForTable];

      const columnNames = [
        'r.b.',
        'Datum unošenja u evidenciju',
        'Broj ugovora',
        'Datum ugovora',
        'Ime i prezime odnosno poslovno ime i adresa nalogodavca #1',
        'Naziv opštine na kojoj se nepokretnost nalazi',
        'Adresa nepokretnosti (mesto, ul. i broj, katastarska parcela, katastarska opština)',
        'Vrsta nepokretnosti čiji je promet odnosno zakup predmet posredovanja #2',
        'Površina nepokretnosti čiji je promet odnosno zakup predmet posredovanja',
        'Pravni posao koji je predmet posredovanja je zaključen/nije #3',
        'Datum zaključenja pravnog posla #4',
        'Kupoprodajna cena ili zakupnina #5',
        'Fakturisani iznos posredničke naknade',
        'Primedba',
      ];
      const columns = columnNames.map((columnName, index) => ({ name: columnName, key: `col${index + 1}` }));

      // Populate the cells with table data
      worksheet.addTable({
        name: 'EopTable',
        ref: range,
        headerRow: true,
        columns: columns,
        rows: tableData,
      });

      worksheet.getColumn(1).width = 5;
      worksheet.getColumn(2).width = 13;
      worksheet.getColumn(3).width = 13;
      worksheet.getColumn(4).width = 13;
      worksheet.getColumn(5).width = 20;
      worksheet.getColumn(6).width = 15;
      worksheet.getColumn(7).width = 20;
      worksheet.getColumn(8).width = 15;
      worksheet.getColumn(9).width = 15;
      worksheet.getColumn(10).width = 15;
      worksheet.getColumn(11).width = 15;
      worksheet.getColumn(12).width = 15;
      worksheet.getColumn(13).width = 15;
      worksheet.getColumn(14).width = 20;
      worksheet.getRow(4).height = 15;
      worksheet.getRow(5).height = 25;
      worksheet.getRow(6).height = 25;
      worksheet.getRow(8).height = 60;

      const startRowIndex = worksheet.getCell(startCell).row;
      const startColumnIndex = worksheet.getCell(startCell).col;
      const endRowIndex = worksheet.getCell(endCell).row;
      const endColumnIndex = worksheet.getCell(endCell).col;

      for (let rowIndex = startRowIndex; rowIndex <= endRowIndex; rowIndex++) {
        for (let columnIndex = startColumnIndex; columnIndex <= endColumnIndex; columnIndex++) {
          const cell = worksheet.getCell(rowIndex, columnIndex);
          cell.alignment = { wrapText: true, horizontal: 'center' };
          cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
          };
        }
      }
      for (let index = 10; index < 10 + dataForTable.length; index++) {
        worksheet.getRow(index).height = 40;
        worksheet.getRow(index).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
      }

      worksheet.getCell(`A${dataForTable.length + 9 + 1}`).value = '#1';
      worksheet.getCell(`A${dataForTable.length + 9 + 2}`).value = '#2';
      worksheet.getCell(`A${dataForTable.length + 9 + 3}`).value = '#3';
      worksheet.getCell(`A${dataForTable.length + 9 + 4}`).value = '#4';
      worksheet.getCell(`A${dataForTable.length + 9 + 5}`).value = '#5';

      worksheet.getCell(`B${dataForTable.length + 9 + 1}`).value =
        'Član 15. stav 2. Zakona o posredovanju u prometu i zakupu nepokretnosti („Službeni glasnik RS“, broj 95/13): „Nalogodavac može da bude prodavac, kupac, zakupodavac ili zakupac, odnosno punomoćnik nekog od tih lica“.';
      worksheet.mergeCells(`B${dataForTable.length + 9 + 1}:N${dataForTable.length + 9 + 1}`);

      worksheet.getCell(`B${dataForTable.length + 9 + 2}`).value =
        'Prilikom evidentiranja vrste nepokretnosti posrednik unosi neki od sledećih podataka: poljoprivredno zemljište, građevinsko zemljište, poslovni prostor, lokal, magacin, industrijski objekat, stan, kuća, garaža, ostalo (uz detaljniji opis u zagradi) ';
      worksheet.mergeCells(`B${dataForTable.length + 9 + 2}:N${dataForTable.length + 9 + 2}`);

      worksheet.getCell(`B${dataForTable.length + 9 + 3}`).value = 'Unosi se: „zaključen“ ili „nije zaključen“.';
      worksheet.mergeCells(`B${dataForTable.length + 9 + 3}:N${dataForTable.length + 9 + 3}`);

      worksheet.getCell(`B${dataForTable.length + 9 + 4}`).value =
        'Unosi se datum zaključenja pravnog posla koji je predmet posredovanja: kupoprodajnog ugovora, odnosno ugovora o zakupu.';
      worksheet.mergeCells(`B${dataForTable.length + 9 + 4}:N${dataForTable.length + 9 + 4}`);

      worksheet.getCell(`B${dataForTable.length + 9 + 5}`).value =
        'Unosi se iznos iz kupoprodajnog ugovora, odnosno ugovora o zakupu.';
      worksheet.mergeCells(`B${dataForTable.length + 9 + 5}:N${dataForTable.length + 9 + 5}`);

      worksheet.mergeCells(`M${dataForTable.length + 9 + 8}:N${dataForTable.length + 9 + 8}`);

      // Set bottom border for the merged cell
      const mergedCellMN = worksheet.getCell(`M${dataForTable.length + 9 + 8}`);
      mergedCellMN.border = {
        bottom: { style: 'thick' },
      };

      worksheet.getCell(`M${dataForTable.length + 9 + 9}`).value = 'Potpis odgovornog lica';
      worksheet.getCell(`M${dataForTable.length + 9 + 9}`).alignment = { horizontal: 'center' };
      // Merge cells M3 to N3
      worksheet.mergeCells(`M${dataForTable.length + 9 + 9}:N${dataForTable.length + 9 + 9}`);

      //Excel styles
      const defaultFont = { name: 'Times New Roman', size: 12 };
      worksheet.eachRow((row) => {
        row.eachCell((cell) => {
          cell.font = defaultFont;
        });
      });
      cellC1.font = { name: 'Times New Roman', bold: true, size: 12 };
      cellC2.font = { name: 'Times New Roman', bold: true, size: 12 };
      cellC3.font = { name: 'Times New Roman', bold: true, size: 12 };
      cellC4.font = { name: 'Times New Roman', bold: true, size: 12 };
      worksheet.getCell('A5').font = { name: 'Times New Roman', bold: true, size: 16 };
      worksheet.getCell('A6').font = { name: 'Times New Roman', bold: true, size: 16 };

      // Define the range for the cells
      const startCellA8 = 'A8';
      const endCellN8 = 'N8';

      // Set font size to 9 for the range A8:N8
      for (let col = startCellA8.charCodeAt(0); col <= endCellN8.charCodeAt(0); col++) {
        const cell = worksheet.getCell(String.fromCharCode(col) + '8');
        cell.font = { size: 9, bold: true, name: 'Times New Roman' };
      }

      worksheet.getRow(9).font = { size: 10, bold: true, name: 'Times New Roman' };
      const startCellA9 = 'A9';
      const endCellN9 = 'N9';
      for (let col = startCellA9.charCodeAt(0); col <= endCellN9.charCodeAt(0); col++) {
        const cell = worksheet.getCell(String.fromCharCode(col) + '9');
        cell.fill = {
          type: 'pattern',
          pattern: 'darkVertical',
          fgColor: {
            argb: '575757',
          },
        };
      }

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, `eop-${year}.xlsx`);
      });
      notification.success({
        message: `Uspešno exportovanje eop-a za ${year}. godinu.`,
        placement: 'bottomRight',
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Problem pri eksportovanju. Molimo da pokušate kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='table'>
      <div className='actions-block'>
        <Link to='/admin/create-eop'>
          <Button type='primary'>Kreiranje EOP-a</Button>
        </Link>
        <h4>Broj nekretnina: {eopCount}</h4>
        <div className='eop-export'>
          <DatePicker onChange={onChange} picker='year' />
          {!year ? (
            <Button
              type='primary'
              onClick={() => handleDownload(data, year)}
              title='Molimo Vas da izaberete godinu pre exportovanja.'
              disabled
            >
              Export u excel
            </Button>
          ) : (
            <Button
              type='primary'
              onClick={() => handleDownload(data, year)}
              title='Exportovanje eop-a za izabranu godinu.'
            >
              Export u excel
            </Button>
          )}
        </div>
      </div>

      <div style={{ textAlign: 'center' }}>
        {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteDataHandler}
            columnKeys={updatedColumnKeys}
            title='Data'
            editPath='/admin/edit-eop/'
          />
        )}
        {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && (
          <div className='no-data-box'>
            <h2>Nema podataka</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Eop;
