import React, { useEffect, useState } from 'react';
// import slugify from 'slugify';
import { getTreeFromFlatData } from 'react-sortable-tree';
import JoditEditor from 'jodit-react';
import { Tabs, Divider, Input, Checkbox, Radio, Select, DatePicker, Button, Form, Row, Col, TreeSelect } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import useAxios from '../../hooks/useAxios';
import UploadBox from '../base/UploadBox';
import DNDGallery from '../base/DNDGallery';
import DNDPrivGallery from '../base/DNDPrivGallery';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/sr_RS';
import { GalleryModal } from './';

const { Option } = Select;

const formInit = {
  _id: null,
  location: '',
  structure: '',
  type: '',
  client: '',
  category: '',
  priceFrom: 0,
  priceTo: 0,
  note: '',
  squareMeterFrom: 0,
  squareMeterTo: 0,
  rooms: 0,
  notCurrentDate: '',
};

const category = [
  { label: 'prodaja', value: 'prodaja' },
  { label: 'prodaja/zamena', value: 'prodaja/zamena' },
  { label: 'izdavanje', value: 'izdavanje' },
];

const type = [
  { label: 'kuća', value: 'kuća' },
  { label: 'stan', value: 'stan' },
  { label: 'garsonjera', value: 'garsonjera' },
  { label: 'poslovni prostor', value: 'poslovni prostor' },
  { label: 'vikendica', value: 'vikendica' },
  { label: 'plac', value: 'plac' },
  { label: 'zemlja', value: 'zemlja' },
  { label: 'garaža', value: 'garaža' },
  { label: 'salaš', value: 'salaš' },
  { label: 'voćnjak / vinograd', value: 'voćnjak/vinograd' },
];

const rooms = Array.from({ length: 16 }, (_, index) => (
  <Radio key={index} value={index}>
    {index}
  </Radio>
));

const DemandForm = ({ isNew, data, clients, locations, structures, language, onSubmit, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });

  if (data) ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete data[key]);

  let initialValues = { ...formInit, ...data };

  if (data) {
    if (data.notCurrentDate) form.setFieldsValue({ notCurrentDate: moment.utc(data.notCurrentDate) });
    if (data.client) {
      form.setFieldsValue({
        client: data.client._id,
      });
      form.setFields([
        {
          name: 'client',
          placeholder: `${data.client.nid} ${data.client.clientName}`,
        },
      ]);
    }
    if (data.location) {
      form.setFieldsValue({
        location: data.location._id,
      });
      form.setFields([
        {
          name: 'location',
          placeholder: `${data.location.locationName}`,
        },
      ]);
    }
    if (data.structure) {
      form.setFieldsValue({
        structure: data.structure._id,
      });
      form.setFields([
        {
          name: 'structure',
          placeholder: `${data.structure.structureName}`,
        },
      ]);
    }
  }
  useEffect(() => {
    if (data) {
      if (data.featuredAdUntil) form.setFieldsValue({ featuredAdUntil: dayjs(data.featuredAdUntil) });
    }
  }, [data, form, SERVER_URL, initialValues]);

  const onFinish = async (values, isNew) => {
    // values.clientId = Number(values.seller.substring(0, values.seller.indexOf(' ')));
    // values.seller = values.seller.substring(values.seller.indexOf(' ') + 1);
    if (values.contractDate === null) {
      values.contractDate = '';
    }
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  /**
   * Insert image/images in form by form key
   * @param {[Object]} values
   * @param {String} formKey
   */
  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    if (modal.formKey === 'gallery') {
      setCurrentGallery(values);
    }
    if (modal.formKey === 'privGallery') {
      setCurrentPrivGallery(values);
    }
    if (modal.formKey === 'featureImage') {
      setImage(values);
    }
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24}>
              <div className='panel-body'>
                <Row type='flex' gutter={16}>
                  <Col span={4}>
                    <Form.Item
                      label='Klijent'
                      name='client'
                      rules={[
                        {
                          required: true,
                          message: 'Molimo Vas da unesete klijenta!',
                        },
                      ]}
                    >
                      <Select
                        virtual={false}
                        allowClear='true'
                        showSearch
                        placeholder='Pretražite prodavca/klijenta...'
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.props.children
                            .normalize('NFD') // Normalize diacritic characters
                            .toLowerCase()
                            .indexOf(input.normalize('NFD').toLowerCase()) >= 0
                        }
                      >
                        {clients.map((client) => (
                          <Option key={client.nid} value={client._id}>{`${client.nid} ${client.clientName}`}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row type='flex' gutter={16}>
                  <Col span={4}>
                    <Form.Item label='Struktura' name='structure'>
                      <Select
                        virtual={false}
                        showSearch
                        allowClear='true'
                        placeholder='Pretražite strukture...'
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.props.children
                            .normalize('NFD') // Normalize diacritic characters
                            .toLowerCase()
                            .indexOf(input.normalize('NFD').toLowerCase()) >= 0
                        }
                      >
                        {structures.map((structure) => (
                          <Option key={structure._id} value={structure._id}>
                            {structure.structureName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label='Vrsta'
                      name='type'
                      rules={[
                        {
                          required: true,
                          message: 'Molimo Vas da unesete vrstu!',
                        },
                      ]}
                    >
                      <Radio.Group>
                        {type.map((option) => (
                          <Radio key={option.value} value={option.value}>
                            {option.label}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label='Kategorija'
                      name='category'
                      rules={[
                        {
                          required: true,
                          message: 'Molimo Vas da unesete kategoriju!',
                        },
                      ]}
                    >
                      <Radio.Group>
                        {category.map((option) => (
                          <Radio key={option.value} value={option.value}>
                            {option.label}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label='Sobe'
                      style={{ display: 'flex', gap: '10px', alignItems: 'flex-start' }}
                      name='rooms'
                    >
                      <Radio.Group>{rooms}</Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row type='flex' gutter={16}>
                  <Col span={4}>
                    <Form.Item
                      label='Lokacija'
                      name='location'
                      rules={[
                        {
                          required: true,
                          message: 'Molimo Vas da unesete lokaciju!',
                        },
                      ]}
                    >
                      <Select
                        virtual={false}
                        showSearch
                        allowClear='true'
                        placeholder='Pretražite lokacije...'
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.props.children
                            .normalize('NFD') // Normalize diacritic characters
                            .toLowerCase()
                            .indexOf(input.normalize('NFD').toLowerCase()) >= 0
                        }
                      >
                        {locations.map((location) => (
                          <Option key={location._id} value={location._id}>
                            {location.locationName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row type='flex' gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      label='Kvadratura od'
                      style={{ width: 200 }}
                      name='squareMeterFrom'
                      rules={[
                        {
                          required: true,
                          message: 'Molimo Vas da unesete kvadraturu od!',
                        },
                      ]}
                    >
                      <Input type='number' suffix='m²' />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label='Kvadratura do'
                      style={{ width: 200 }}
                      name='squareMeterTo'
                      rules={[
                        {
                          required: true,
                          message: 'Molimo Vas da unesete kvadraturu do!',
                        },
                      ]}
                    >
                      <Input type='number' suffix='m²' />
                    </Form.Item>
                  </Col>
                </Row>
                <Row type='flex' gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      label='Cena od'
                      style={{ width: 200 }}
                      name='priceFrom'
                      rules={[
                        {
                          required: true,
                          message: 'Molimo Vas da unesete cenu od!',
                        },
                      ]}
                    >
                      <Input type='number' suffix='€' />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label='Cena do'
                      style={{ width: 200 }}
                      name='priceTo'
                      rules={[
                        {
                          required: true,
                          message: 'Molimo Vas da unesete cenu do!',
                        },
                      ]}
                    >
                      <Input type='number' suffix='€' />
                    </Form.Item>
                  </Col>
                </Row>
                <Col span={8}>
                  <Form.Item label='Nije aktuelno od' name='notCurrentDate'>
                    <DatePicker locale={locale} format='DD-MM-YYYY' />
                  </Form.Item>
                </Col>
                <Row type='flex' gutter={16}>
                  <Col span={16}>
                    <Form.Item label='Beleške' name='note'>
                      <TextArea rows={7} placeholder='Unesite beleške...' />
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={8}>
                    <Form.Item label='Featured' name='featuredProduct' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                  </Col>
                </Row> */}

                {/* <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={8}>
                    <Form.Item label='Featured' name='featuredAd' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item label='Featured until' name='featuredAdUntil' getValueProps={() => {}} rules={[{ type: 'object' }]}>
                      <DatePicker style={{ width: '100%' }} size='large' rules={[{ type: 'object' }]} />
                    </Form.Item>
                  </Col>
                </Row> */}
              </div>
            </Col>
          </Row>
          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Kreiraj' : 'Ispravi'} potražnju
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default DemandForm;
