import React, { useState, useEffect } from 'react';
import { Tabs, Divider, Input, Button, Form } from 'antd';

const formInit = {
  value: {},
};

const ValueForm = ({ setEdit, attrId, language, onSubmit, value }) => {
  const [form] = Form.useForm();
  const [tab, setTab] = useState(language.selected.code);
  ['createdAt', 'updatedAt', '__v'].forEach((key) => delete value[key]);
  let initialValues = { ...formInit, ...value };

  useEffect(() => {
    form.resetFields();
  }, [value, form]);

  return (
    <div className='panel panel-primary'>
      <div className='panel-heading'>
        <h4 className='panel-title'>
          <span>ADD / EDIT VALUE</span>
          {/* <span
            style={{ cursor: 'pointer', padding: '6px', color: 'grey' }}
            onClick={() => {
              setEdit({ visible: false, attrId: null, value: {} });
            }}
          >
            X
          </span> */}

          <button className='btn-close' onClick={() => setEdit({ visible: false, attrId: null, value: {} })}>
            <span className='icon'>&#10005;</span>
          </button>
        </h4>
      </div>
      <div className='panel-body'>
        <Form
          layout='vertical'
          initialValues={initialValues}
          onFinish={(values) => onSubmit(values, attrId, !value.value)}
          form={form}
        >
          <h3 style={{ marginBottom: '30px' }}>Add a value</h3> <div style={{ color: 'white' }}>{tab} language</div>
          <Tabs onChange={(key) => setTab(key)} tabPosition='left' defaultActiveKey={language.default.code}>
            {language.list.map((lang) => (
              <Tabs.TabPane tab={lang.code} key={lang.code} forceRender>
                <Form.Item
                  label='Value'
                  rules={[
                    { required: lang.code === language.default.code ? true : false, message: 'Please enter value!' },
                  ]}
                  name={['value', lang.code]}
                >
                  <Input />
                </Form.Item>
              </Tabs.TabPane>
            ))}
          </Tabs>
          <Divider type='horizontal' />
          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ValueForm;
