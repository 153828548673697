import React, { useContext } from 'react';
import { Table, Input, Button, Popconfirm, Checkbox } from 'antd';
import { DeleteFilled, DeleteOutlined, EditFilled, EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';

const EopTable = ({ data, deleteHandler, columnKeys, columnKeysTranslated, title, editPath, viewPath, showButton }) => {
  const user = useContext(UserContext);
  let searchInput;
  const columnKeyTranslator = (key) => {
    let translatedKey;
    let width;

    switch (key) {
      case 'price':
        translatedKey = 'Kupoprodajna cena ili zakupnina #5';
        break;
      case 'squareMeter':
        translatedKey = 'Površina nepokretnosti čiji je promet odnosno zakup predmet posredovanja';
        break;
      case 'contractDate':
        translatedKey = 'datum ugovora';
        break;
      case 'contractNum':
        translatedKey = 'Broj ugovora';
        break;
      case 'client':
        translatedKey = 'Ime i prezime odnosno poslovno ime i adresa nalogodavca #1';
        break;
      case 'type':
        translatedKey = 'Vrsta nepokretnosti čiji je promet odnosno zakup predmet posredovanja #2';
        break;
      case 'address':
        translatedKey = 'Adresa nepokretnosti (mesto, ul. i broj, katastarska parcela, katastarska opština)';
        break;
      case 'city':
        translatedKey = 'Naziv opštine na kojoj se nepokretnost nalazi';
        break;
      case 'locationName':
        translatedKey = 'lokacija';
        break;
      case 'eopId':
        translatedKey = 'id';
        break;
      case 'entryDate':
        translatedKey = 'Datum unošenja u evidenciju';
        break;
      case 'note':
        translatedKey = 'Primedba';
        break;
      case 'notCurrentDate':
        translatedKey = 'Datum zaključenja pravnog posla #4';
        break;
      default:
        console.log('Error', 'Column key not found in array');
    }
    return { title: translatedKey, width: width };
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Pretraži`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Pretraži
          </Button>
          <Button type='secondary' onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Vrati
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? !dataIndex.includes('category')
          ? record[dataIndex[0]][dataIndex[1]]
          : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const sortOrder = [
    'eopId',
    'entryDate',
    'contractNum',
    'contractDate',
    'client',
    'city',
    'address',
    'type',
    'squareMeter',
    'notCurrentDate',
    'price',
    'note',
  ];

  const customSort = (a, b) => {
    const indexA = sortOrder.indexOf(a);
    const indexB = sortOrder.indexOf(b);
    return indexA - indexB;
  };
  columnKeys.sort(customSort);
  const columns = columnKeys.map((item) => {
    return {
      key: item,
      title: columnKeyTranslator(item).title,
      dataIndex: item,
      sorter:
        columnKeyTranslator(item).title === 'Kupoprodajna cena ili zakupnina #5' ? (a, b) => a.price - b.price : false,
      render:
        columnKeyTranslator(item).title === 'Kupoprodajna cena ili zakupnina #5'
          ? (item) => (item ? `${item.toLocaleString()} €` : 'Nema podatka')
          : (item) => `${item}`,
      ...getColumnSearchProps(item),
      ellipsis: true,
      // Optional: Enable text ellipsis if the content exceeds cell width
    };
  });

  columns.push({
    title: 'Akcije',
    //width: '10vw' /*bilo je bez width,ali se tada ne poravnaju linije header i body*/,
    render: (text, record) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: '15px',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
            fontSize: '15px',
          }}
        >
          {showButton !== false && (
            <div style={{ cursor: 'pointer' }} className='lock'>
              <Link to={editPath + record._id}>
                <EditOutlined
                  className='icon-unlock'
                  title={`Izmena eop-a`}
                  style={{ textDecoration: 'none', color: 'black' }}
                />
                <EditFilled
                  className='icon-lock'
                  title={`Izmena eop-a`}
                  style={{ textDecoration: 'none', color: 'black' }}
                />
              </Link>
            </div>
          )}

          {/* {viewPath && (
            <div style={{ cursor: 'pointer' }} className='lock'>
              <Link to={viewPath + record._id}>
                <EyeOutlined
                  className='icon-unlock'
                  title={`Pregled nekretnine`}
                  style={{ textDecoration: 'none', color: 'black' }}
                />
                <EyeOutlined
                  className='icon-lock'
                  title={`Pregled nekretnine`}
                  style={{ textDecoration: 'none', color: 'black' }}
                />
              </Link>
            </div>
          )} */}

          {showButton !== false && (
            <div style={{ cursor: 'pointer' }} className='lock'>
              <Popconfirm
                disabled={record._id === user.data.id}
                placement='left'
                title={`Da li želite da obrišete eop?`}
                onConfirm={() => {
                  deleteHandler(record._id);
                }}
                okText='Da'
                cancelText='Ne'
                cancelButtonProps={{ type: 'secondary' }}
                okButtonProps={{ type: 'primary' }}
              >
                <DeleteOutlined className='icon-unlock' title={`Brisanje eop-a}`} />
                <DeleteFilled className='icon-lock' title={`Brisanje eop-a`} />
              </Popconfirm>
            </div>
          )}
        </div>
        {/* {showButton !== false && (
          <div style={{ cursor: 'pointer' }} className='lock'>
            <Checkbox
              onClick={() => {
                postCetiriZida(record._id);
              }}
              checked={record.postedCetiriZida === true}
              title={`Objavljivanje na četiri zida`}
            />
          </div>
        )} */}
      </div>
    ),
  });

  return (
    <div className='table-wrapper eop-table'>
      {' '}
      {/* style={{ height: '85vh' }} */}
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 50,
          position: ['topCenter','bottomCenter'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '150'],
          hideOnSinglePage: true,
        }}
        scroll={{ y: '88vh' }} // 74vh
        fixed
      />
    </div>
  );
};

export default EopTable;
