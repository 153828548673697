import React, { useEffect, useContext, useState } from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { Avatar, Button, notification, Layout, Tabs, Typography, Card, Row, Col, Collapse } from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserLog } from '../../components/forms';
import dayjs from 'dayjs';
// import Geocode from 'react-geocode';
import DataForm from '../../components/forms/DataForm';
const { Panel } = Collapse;
const { Content, Sider } = Layout;
const { Text } = Typography;
const { TabPane } = Tabs;

// Geocode.setApiKey('AIzaSyD0XgE7IemaK0qAmFdbNsfBucSnoJ4wVVs');

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const ViewDataContract = (props) => {
  const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [eop, fetchEop] = useAxios('', [], currentuser.data.token, 'get');
  const [clients, fetchClients] = useAxios('', {}, currentuser.data.token, 'get');
  const [coOwners, fetchCoOwners] = useAxios('', {}, currentuser.data.token, 'get');
  const [commissioners, fetchCommissioners] = useAxios('', {}, currentuser.data.token, 'get');
  const [locations, fetchLocations] = useAxios('', {}, currentuser.data.token, 'get');
  const [structures, fetchStructures] = useAxios('', {}, currentuser.data.token, 'get');
  const [toggleGallery, setToggleGallery] = useState(false);
  const [arrayImages, setArrayImages] = useState([]);
  const [coordinate, setCoordinate] = useState({});
  const [address, setAddress] = useState();
  let clientsData = clients.data;
  let locationsData = locations.data;
  let structuresData = structures.data;
  let coOwnersData = coOwners.data;
  let commissionersData = commissioners.data;
  const history = useHistory();

  const eopData = eop.data?.items?.[0];
  // const addressClients = [
  //   eopData?.realEstate?.client?.address,
  //   ...eopData?.coOwners.map((coowner) => coowner.coowner.address),
  // ].join(', ');
  useEffect(() => {
    if (id) {
      fetchData(`${SERVER_URL}/data-view`, []);
      fetchEop(`${SERVER_URL}/eop?filter=` + encodeURIComponent(JSON.stringify({ realEstate: id })), []);
      fetchClients(
        `${SERVER_URL}/clients?filter=` +
          encodeURIComponent(
            JSON.stringify({
              clientType: { $in: ['prodavac'] },
            }),
          ),
        [],
      );
      fetchCoOwners(
        `${SERVER_URL}/clients?filter=` +
          encodeURIComponent(
            JSON.stringify({
              clientType: { $in: ['suvlasnik'] },
            }),
          ),
        [],
      );
      fetchCommissioners(
        `${SERVER_URL}/clients?filter=` +
          encodeURIComponent(
            JSON.stringify({
              clientType: { $in: ['punomoćnik'] },
            }),
          ),
        [],
      );
      fetchLocations(`${SERVER_URL}/locations`, []);
      fetchStructures(`${SERVER_URL}/structures`, []);
      fetchData(`${SERVER_URL}/data-view/${id}`, {});
    }
  }, [
    id,
    eop,
    fetchData,
    fetchClients,
    fetchLocations,
    fetchStructures,
    clientsData,
    locationsData,
    structuresData,
    coOwnersData,
    commissionersData,
  ]);

  const getUrl = (itemUrl) => {
    let url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;

    return url;
  };

  // useEffect(() => {
  //   if (id) {
  //     let sevenDayBefor = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
  //     let sevenDayBeforGet = dayjs(sevenDayBefor).format('YYYY-MM-DD');
  //     let from = sevenDayBeforGet;
  //     let to = new Date();
  //     let fromTo = from + '||' + to;
  //     fetchCSV(`${SERVER_URL}/logsCSV/${id}/${JSON.stringify(fromTo)}`, []);
  //   }
  // }, [id, fetchCSV]);

  // const updateUser = async (data) => {
  //   if (data) {
  //     let fromTo = data.from + '||' + data.to;
  //     fetchCSV(`${SERVER_URL}/logsCSV/${id}/${JSON.stringify(fromTo)}`, []);
  //   }
  // };

  // const handleSignOutSubmit = async (event) => {
  //   event.preventDefault();
  //   sessionStorage.removeItem('user');
  //   window.location.href = '/login';
  // };
  const isDataFetched = !data.isLoading && data.data && currentuser.language;
  const isClientsFetched = !clients.isLoading && clientsData.items;
  const isLocationsFetched = !locations.isLoading && locationsData.items;
  const isStructuresFetched = !structures.isLoading && structuresData.items;
  const isCoOwnersFetched = !coOwners.isLoading && coOwnersData.items;
  const isCommissionersFetched = !commissioners.isLoading && commissionersData.items;
  // const getCoordinate = async (address, city) => {
  //   return new Promise((resolve, reject) => {
  //     Geocode.fromAddress(`${address} ${city}`).then(
  //       (response) => {
  //         const { lat, lng } = response.results[0].geometry.location;
  //         resolve({ lat, lng });
  //       },
  //       (error) => {
  //         console.log(error);
  //         reject(error);
  //       },
  //     );
  //   });
  // };

  // const mainFunction = async () => {
  //   const coordinates = await getCoordinate(data.data.address, data.data.city);

  //   setCoordinate(coordinates);
  // };

  const onSubmit = async (formData, isNew) => {
    const method = 'put';
    const route = `${SERVER_URL}/data-view/${id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Nekretnina ${isNew ? 'je kreirana.' : 'je ažurirana.'}`,
        placement: 'bottomRight',
      });

      if (isNew) {
        history.push('/admin/data-contract');
        window.location.reload();
      }
      if (!isNew) {
        history.push(`/admin/view-data-contract/${id}`);
        window.location.reload();
      }
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;

      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    if (data.data.featureImage && data.data.gallery.length >= 0 && data.data.gallery.length >= 0) {
      setArrayImages([
        { src: getUrl(data.data.featureImage.url) },
        ...data?.data?.gallery.map((image) => {
          return { src: getUrl(image.url) };
        }),
        ...data?.data?.privGallery.map((image) => {
          return { src: getUrl(image.url) };
        }),
      ]);
      setToggleGallery(true);
    }
  }, [data]);

  // useEffect(() => {
  //   mainFunction();

  //   if (data.data.address) {
  //     setAddress(data.data.address.replaceAll(' ', '+'));
  //   }
  // }, [data.data.address, data.data.city]);

  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Link to='/admin/data-contract'>
          <Button type='primary'>Nazad na nekretnine</Button>
        </Link>
      </div>
      <div className='content content-full-height'>
        {id && data.isLoading && <LoadingOutlined className='loader' spin />}
        {id && !data.isLoading && data.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {id && data && !data.isLoading && !data.isError && (
          <Layout className='site-layout-background'>
            <Content style={{ padding: '0 15px', minHeight: 280, background: '#fff' }}>
              <h2 className='data-title'>{`${data.data.city} ${data.data.type} ${data.data.heating} ${data?.data?.location?.locationName}`}</h2>
              <Tabs defaultActiveKey='1' /* onChange={callback} */>
                <TabPane tab='PREGLED' key='1'>
                  <div
                    className={`${
                      data.data.sold === true || data.data.rented === true
                        ? 'card-wrapper data-view-background'
                        : 'card-wrapper'
                    }`}
                  >
                    <Card title='Podaci o nekretnini' bordered={false}>
                      <div className='data-view-grid'>
                        <div>
                          <p>
                            <Text strong>Evidencioni broj: </Text>
                            {data.data ? data.data.eb : <Text strong>Nema podatka</Text>}
                          </p>
                          <p style={{ textTransform: 'capitalize' }}>
                            <Text strong>Prodavac: </Text>
                            {data.data.client ? (
                              <Link to={`/admin/view-client/${data?.data?.client?._id}`}>
                                {data?.data?.client?.clientName}
                              </Link>
                            ) : (
                              <Text strong>Nema podatka</Text>
                            )}
                          </p>
                          <p style={{ textTransform: 'capitalize' }}>
                            <Text strong>Kategorija: </Text>
                            {data.data.category ? data.data.category : <Text strong>Nema podatka</Text>}
                          </p>
                          <p style={{ textTransform: 'capitalize' }}>
                            <Text strong>Vrsta: </Text>
                            {data.data.type ? data.data.type : <Text strong>Nema podatka</Text>}
                          </p>
                          <p>
                            <Text strong>Adresa: </Text>
                            {data.data.address ? data.data.address : <Text strong>Nema podatka</Text>}
                          </p>
                          <p>
                            <Text strong>Lokacija: </Text>
                            {data.data.location ? data.data.location.locationName : <Text strong>Nema podatka</Text>}
                          </p>

                          <p>
                            <Text strong>Grad: </Text>
                            {data.data.city ? data.data.city : <Text strong>Nema podatka</Text>}
                          </p>

                          <p>
                            <Text strong>Kvadratura: </Text>
                            {data.data.squareMeter ? data.data.squareMeter + ' m²' : <Text strong>Nema podatka</Text>}
                          </p>
                          <p>
                            <Text strong>Kvadratura placa: </Text>
                            {data.data.landSurface ? data.data.landSurface + ' m²' : <Text strong>Nema podatka</Text>}
                          </p>
                          <p>
                            <Text strong>Sobe: </Text>
                            {data.data.rooms ? data.data.rooms : <Text strong>Nema podatka</Text>}
                          </p>
                          <p style={{ textTransform: 'capitalize' }}>
                            <Text strong>Grejanje: </Text>
                            {data.data.heating ? data.data.heating : <Text strong>Nema podatka</Text>}
                          </p>
                          <p>
                            <Text strong>Cena: </Text>
                            {data.data.price ? data.data.price + ' €' : <Text strong>Nema podatka</Text>}
                          </p>
                          <Text strong>Beleške: </Text>
                          <Collapse
                            style={{
                              maxWidth: '500px',
                              marginBottom: '20px',
                              wordWwrap: 'break-word',
                              overflowWrap: 'break-word',
                              overflow: 'hidden',
                            }}
                          >
                            <Panel header='Prikaži beleške' key='1'>
                              {data.data.privDesc ? (
                                <div
                                  style={{ maxWidth: '500px' }}
                                  dangerouslySetInnerHTML={{ __html: data.data.privDesc }}
                                ></div>
                              ) : (
                                <Text strong>Nema podatka</Text>
                              )}
                            </Panel>
                          </Collapse>
                          <Text strong>Opis za web stranicu: </Text>
                          {data.data.webDesc ? (
                            <div
                              style={{ maxWidth: '500px' }}
                              dangerouslySetInnerHTML={{ __html: data.data.webDesc.sr }}
                            ></div>
                          ) : (
                            <Text strong>Nema podatka</Text>
                          )}
                        </div>
                        {/* {data.data.city && data.data.address ? (
                          <div
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                            dangerouslySetInnerHTML={{
                              __html: `<iframe
                            width="900"
                            height="500"
                            frameborder="0" style="border:0"
                            referrerpolicy="no-referrer-when-downgrade"
                            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD0XgE7IemaK0qAmFdbNsfBucSnoJ4wVVs&q=${address},${data.data.city}+Serbia&center=${coordinate.lat},${coordinate.lng}
                            &zoom=18
                            &maptype=roadmap"
                            allowfullscreen>
                          </iframe>`,
                            }}
                          />
                        ) : null} */}
                      </div>
                    </Card>
                    {toggleGallery ? (
                      <div
                      // style={{ float: 'right', marginRight: '24px', marginBottom: '50px' }}
                      >
                        <Text strong>Galerija: </Text>
                        <Carousel images={arrayImages} style={{ height: 500, maxWidth: 900 }} />
                      </div>
                    ) : null}
                  </div>
                </TabPane>
                {eopData && (
                  <TabPane tab='EOP' key='2'>
                    <div className='card-wrapper'>
                      <Card title='Eop podaci' bordered={false}>
                        <div className='data-view-grid'>
                          <div>
                            <p>
                              <Text strong>Ime (naziv) stranke: </Text>
                              {eopData.client ? eopData.client : <Text strong>Nema podatka</Text>}
                            </p>
                            <p style={{ textTransform: 'capitalize' }}>
                              <Text strong>Suvlasnici: </Text>
                              {eopData.coOwners.length !== 0 ? (
                                eopData.coOwners.map((coowner) => <Text>{coowner.name} </Text>)
                              ) : (
                                <Text strong>Nema podatka</Text>
                              )}
                            </p>
                            <p style={{ textTransform: 'capitalize' }}>
                              <Text strong>Adresa stranke: </Text>
                              {eopData.addressClients ? eopData.addressClients : <Text strong>Nema podatka</Text>}
                            </p>
                            <p style={{ textTransform: 'capitalize' }}>
                              <Text strong>Vrsta lica: </Text>
                              {eopData.typeClient ? eopData.typeClient : <Text strong>Nema podatka</Text>}
                            </p>
                            <p>
                              <Text strong>JMBG (PIB): </Text>
                              {eopData.jmbgPib ? eopData.jmbgPib : <Text strong>Nema podatka</Text>}
                            </p>
                            <p>
                              <Text strong>Broj lične karte (MB): </Text>
                              {eopData.blkMb ? eopData.blkMb : <Text strong>Nema podatka</Text>}
                            </p>
                            <p>
                              <Text strong>Datum rođenja: </Text>
                              {eopData.birthDate ? (
                                new Date(eopData.birthDate).toLocaleString('sr-RS', { dateStyle: 'medium' })
                              ) : (
                                <Text strong>Nema podatka</Text>
                              )}
                            </p>
                            <p>
                              <Text strong>Mesto rođenja: </Text>
                              {eopData.birthPlace ? eopData.birthPlace : <Text strong>Nema podatka</Text>}
                            </p>
                            <p>
                              <Text strong>Delatnost stranke: </Text>
                              {eopData.clientOcupation ? eopData.clientOcupation : <Text strong>Nema podatka</Text>}
                            </p>
                            <div className='demand-wrapper'>
                              <label>Procena rizika</label>
                              <p style={{ textTransform: 'capitalize' }}>
                                <Text strong>Rezidentnonst: </Text>
                                {eopData.residency ? eopData.residency : <Text strong>Nema podatka</Text>}
                              </p>
                              <p style={{ textTransform: 'capitalize' }}>
                                <Text strong>Država prebivališta/sedišta: </Text>
                                {eopData.domicile ? eopData.domicile : <Text strong>Nema podatka</Text>}
                              </p>
                              <p>
                                <Text strong>Rizična stranka: </Text>
                                {eopData.riskyClient ? eopData.riskyClient : <Text strong>Nema podatka</Text>}
                              </p>
                              <p>
                                <Text strong>Dokumentacija: </Text>
                                {eopData.documentation ? eopData.documentation : <Text strong>Nema podatka</Text>}
                              </p>
                              <p>
                                <Text strong>Uspostavljen poslovni odnos: </Text>
                                {eopData.businessShip ? eopData.businessShip : <Text strong>Nema podatka</Text>}
                              </p>
                            </div>
                            <div className='demand-wrapper'>
                              <label>Podaci o ugovoru</label>
                              <p style={{ textTransform: 'capitalize' }}>
                                <Text strong>Broj ugovora: </Text>
                                {eopData.contractNum ? eopData.contractNum : <Text strong>Nema podatka</Text>}
                              </p>
                              <p style={{ textTransform: 'capitalize' }}>
                                <Text strong>Datum ugovora: </Text>
                                {eopData.contractDate ? (
                                  new Date(eopData.contractDate).toLocaleString('sr-RS', { dateStyle: 'medium' })
                                ) : (
                                  <Text strong>Nema podatka</Text>
                                )}
                              </p>
                              <p>
                                <Text strong>Datum isteka ugovora: </Text>
                                {eopData.expireDate ? (
                                  new Date(eopData.expireDate).toLocaleString('sr-RS', { dateStyle: 'medium' })
                                ) : (
                                  <Text strong>Nema podatka</Text>
                                )}
                              </p>
                              <p>
                                <Text strong>Datum evidentiranja: </Text>
                                {eopData.entryDate ? (
                                  new Date(eopData.entryDate).toLocaleString('sr-RS', { dateStyle: 'medium' })
                                ) : (
                                  <Text strong>Nema podatka</Text>
                                )}
                              </p>
                            </div>
                            <p>
                              <Text strong>Primedba: </Text>
                              {eopData.note ? eopData.note : <Text strong>Nema podatka</Text>}
                            </p>
                            <p>
                              <Text strong>Klazula anonimnosti: </Text>
                              {eopData ? eopData.anon === false ? 'Ne' : 'Da' : <Text strong>Nema podatka</Text>}
                            </p>
                            <p style={{ textTransform: 'capitalize' }}>
                              <Text strong>Poreklo imovine: </Text>
                              {eopData.origin ? eopData.origin : <Text strong>Nema podatka</Text>}
                            </p>
                            <p>
                              <Text strong>Razlozi za sumnju u pranje novca: </Text>
                              {eopData.moneyLaunder ? eopData.moneyLaunder : <Text strong>Nema podatka</Text>}
                            </p>
                            <p>
                              <Text strong>Podaci o stvarnim vlasnicima stranke: </Text>
                              {eopData.realOwnerData ? eopData.realOwnerData : <Text strong>Nema podatka</Text>}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </TabPane>
                )}
                <TabPane tab='IZMENA' key='3'>
                  {id &&
                    !data.isError &&
                    data.data &&
                    clientsData &&
                    isDataFetched &&
                    isClientsFetched &&
                    isLocationsFetched &&
                    isStructuresFetched &&
                    isCoOwnersFetched &&
                    isCommissionersFetched && (
                      <DataForm
                        isNew={false}
                        data={data.data}
                        clients={clientsData.items}
                        coOwners={coOwnersData.items}
                        commissioners={commissionersData.items}
                        locations={locationsData.items}
                        structures={structuresData.items}
                        language={currentuser.language}
                        onSubmit={onSubmit}
                        SERVER_URL={SERVER_URL}
                        token={currentuser.data.token}
                      />
                    )}
                </TabPane>
              </Tabs>
            </Content>
          </Layout>
        )}
      </div>
      {/* <EditUserModal userId={id} showModal={showModal} setShowModal={setShowModal} profile={true} /> */}
    </div>
  );
};

export default ViewDataContract;
