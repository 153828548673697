import React, { useState } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip, Popconfirm } from 'antd';
import { SERVER_URL } from '../../config';

const GalleryImage = ({
  image,
  name,
  editHandler,
  imageId,
  selected,
  selectHandler,
  currentSelected,
  allSelected,
  deleteHandler,
}) => {
  const [visible, setVisible] = useState(false);
  const imageUrl = image && image.url ? image.url : image;
  const getUrl = (itemUrl) => {
    let url = '';
    if (itemUrl) url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;
    return url;
  };

  const onMouseEnterHandler = () => {
    const overlay = document.getElementById(`gallery-img-${image._id}`);
    if (overlay) {
      overlay.addEventListener('mouseenter', () => setVisible(true));
    }
    return () => {
      overlay.removeEventListener('mouseenter', () => setVisible(true));
    };
  };

  const onMouseLeaveHandler = () => {
    const overlay = document.getElementById(`gallery-img-${image._id}`);
    if (overlay) {
      overlay.addEventListener('mouseleave', () => setVisible(false));
    }
    return () => {
      overlay.removeEventListener('mouseleave', () => setVisible(false));
    };
  };
  return (
    <>
      {image ? (
        <>
          <Tooltip title={image.originalname}>
            <div
              id={`gallery-img-${image._id}`}
              style={{
                height: '150px',
                position: 'relative',
                background: '#ccc',
                border: '1px solid #ccc',
                borderRadius: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onMouseEnter={onMouseEnterHandler}
              onMouseLeave={onMouseLeaveHandler}
            >
              <img
                src={getUrl(imageUrl)}
                alt={image.originalname}
                style={{ position: 'absolute', maxHeight: '148px', width: 'auto' }}
              />
              <div
                style={{
                  position: 'absolute',
                  display: visible ? 'flex' : 'none',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  width: '100%',
                  height: '100%',
                  background: 'rgba(0, 0, 0, 0.6)',
                  borderRadius: '5px',
                  color: 'white',
                  fontSize: '20px',
                }}
              >
                <EditOutlined
                  style={{ padding: '5px' }}
                  title='Edit image'
                  onClick={() => editHandler(image._id, image.originalname, image.description, image.altTitle)}
                />
                <Popconfirm
                  placement='left'
                  title={`Ovo će obrisati sliku ${image.originalname}`}
                  onConfirm={() => deleteHandler(image._id)}
                  okText='Prihvatam'
                  cancelText='Odustajem'
                >
                  <DeleteOutlined style={{ padding: '5px' }} title='Obriši sliku' />
                </Popconfirm>
              </div>
            </div>
          </Tooltip>
          <p
            style={{
              fontSize: '12px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginLeft: '19px',
              marginTop: '19px',
            }}
          >
            {image.originalname}
          </p>
          <Tooltip title={selected.includes(image._id) ? 'Deselect' : 'Select'}>
            <Checkbox
              style={{ position: 'absolute', marginTop: '-30px' }}
              checked={!allSelected && !selected.includes(image._id) ? false : true}
              onChange={() => selectHandler(image._id)}
              disabled={
                imageId ? image._id !== imageId && selected.includes(image._id) && currentSelected !== image._id : false
              }
            />
          </Tooltip>
        </>
      ) : (
        <h2 style={{ marginTop: '3rem', textAlign: 'center' }}>Nema slika</h2>
      )}
    </>
  );
};

export default GalleryImage;
